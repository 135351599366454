import { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { CustomInput } from '../../components/CustomInput';
import Swal from 'sweetalert2';
import Axios from 'axios';

const logo = require('../../assets/images/logo.png').default;
const chainPic = require('../../assets/images/chain.png').default;
const chainPicMobile = require('../../assets/images/chain_mobile.png').default;

function ResetPassword(props: any) {
    const [pin, setPin] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    useEffect(() => {
        const rpEmail = window.localStorage.getItem("rpEmail");
        if (!rpEmail) {
            props.history.push('/');
        }
    });

    const handlePasswordInputValue = (value: string) => {
        setPassword(value);
    };

    const handleRepeatPasswordInputValue = (value: string) => {
        setRepeatPassword(value);
    };

    const handlePinInputValue = (value: string) => {
        setPin(value);
    };

    const resetPassword = () => {
        if (password !== repeatPassword) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                text: 'رمز عبور و تکرار مطابقت ندارد',
                showConfirmButton: false,
                timer: 3500,
            });
        } else {
            const email = window.localStorage.getItem("rpEmail");
            const reqBody = {
                email,
                password,
                pin
            };
            Axios.post('https://api.mobininvestment.com/public/users/password', reqBody)
                .then(Response => {
                    if (Response.status === 201 || Response.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            text: 'رمز عبور با موفقیت تغییر یافت',
                            showConfirmButton: false,
                            timer: 3500,
                        });

                        window.localStorage.removeItem("rpEmail");
                        props.history.push('/');
                    }
                })
                .catch(error => {
                    console.log(error);
                    const status = error && error.response && error.response.status;
                    if (status === 422) {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            text: 'مشکلی بوجود آمده است لطفا مجددا تلاش کنید',
                            showConfirmButton: false,
                            timer: 3500,
                        });
                    }
                });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.form_wrapper}>
                <img src={logo} alt="logo" />
                <div className={styles.login_title}>
                    <div><span>تغییر رمز عبور</span></div>
                </div>
                <CustomInput
                    type='password'
                    placeholder='رمز عبور جدید'
                    className="text-center"
                    inputValue={password}
                    handleChangeInput={handlePasswordInputValue}
                />
                <CustomInput
                    type='password'
                    placeholder='تکرار رمز عبور'
                    className="text-center"
                    inputValue={repeatPassword}
                    handleChangeInput={handleRepeatPasswordInputValue}
                />
                <CustomInput
                    type='text'
                    placeholder='پین کد ارسالی'
                    className="text-center"
                    inputValue={pin}
                    handleChangeInput={handlePinInputValue}
                />
                <button
                    disabled={pin === '' || password === '' || repeatPassword === ''}
                    className={styles.button}
                    onClick={resetPassword}
                >
                    ثبت
                </button>
            </div>
            <div>
                <img src={chainPic} className={styles.chain_pic} alt="illustration" />
                <img src={chainPicMobile} className={styles.chain_pic_mobile} alt="illustration" />
            </div>
        </div>
    );
}

export default ResetPassword;