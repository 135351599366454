import { Route, Redirect } from 'react-router-dom';
import Order from '../../screens/OrderScreen';
import { Header } from '../../containers/Header';
import { SideBar } from '../../containers/SideBar';
import Accounting from '../../screens/AccountingScreen';
import AdminProfile from '../../screens/AdminProfileScreen';
import UserManagement from '../../screens/UserManagementScreen';
import AdminPortfolio from '../../screens/AdminPortfolioScreen';
import OrderManagement from '../../screens/OrderManagementScreen';
import WalletManagement from '../../screens/WalletManagementScreen';

function AdminRoutes() {
    return (
        <>
            <div className='d-flex flex-row'>
                <div className="d-none d-md-block">
                    <SideBar />
                </div>
                <div className='d-flex flex-column flex-grow-1'>
                    <Header />
                    <div className="d-md-none">
                        <SideBar />
                    </div>
                    <Route path='/' exact component={AdminProfile} />
                    <Route path='/walletmanagement' component={WalletManagement} />
                    <Route path='/ordermanagement' component={OrderManagement} />
                    <Route path='/usermanagement' component={UserManagement} />
                    {/* <Route path='/portfolio' component={AdminPortfolio} /> */}
                    <Route path='/accounting' component={Accounting} />
                    <Route path='/order' component={Order} />
                    <Route>
                        <Redirect to="/" />
                    </Route>
                </div>
            </div>
        </>
    );
}

export default AdminRoutes;