import React, { Component } from 'react';
import styles from './style.module.scss';
import { CustomInput } from '../../components/CustomInput';
import { errorHandler } from '../../helpers/errorHandler';
import { supportedCurrencies } from '../../constants';
import Swal from 'sweetalert2';
import Axios from 'axios';

const addWallet = require('../../assets/icons/icon-add-wallet.svg').default;
const addBinance = require('../../assets/icons/icon-add-binance.svg').default;
const walletIcon = require('../../assets/icons/icon-wallet.svg').default;
const binanceIcon = require('../../assets/icons/icon-binance.svg').default;
class WalletManagement extends Component {
    state = {
        walletsList: [],
        binanceList: [],
        newBinanceAccountForm: false,
        createNewWalletForm: false,
        newWalletLabel: '',
        newWalletAddress: '',
        newWalletCurrency: '',
        newBinanceAccountLabel: '',
        newBinanceAccountAPIKey: '',
        newBinanceAccountSecretKey: '',
    };

    componentDidMount() {
        this.fetchWallets();
        this.fetchBinanceAccounts();
    };

    fetchWallets = () => {
        const token = window.localStorage.getItem('token');

        Axios.get('https://api.mobininvestment.com/wallets/', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    this.setState({ walletsList: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            });

    };

    fetchBinanceAccounts = () => {
        const token = window.localStorage.getItem('token');

        Axios.get('https://api.mobininvestment.com/binance/', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    this.setState({ binanceList: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    currencySelectMapper = () => {
        return (
            supportedCurrencies.map((item, index) => {
                return <option key={index} value={item}>{item}</option>
            })
        );
    };

    createNewWallet = () => {
        const token = window.localStorage.getItem('token');
        const reqBody = {
            label: this.state.newWalletLabel,
            address: this.state.newWalletAddress,
            currency: this.state.newWalletCurrency
        };

        Axios.post('https://api.mobininvestment.com/wallets/', reqBody, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'کیف پول جدید با موفقیت ایجاد شد',
                        showConfirmButton: false,
                        timer: 3500,
                    });

                    this.fetchWallets();
                }
            })
            .catch(error => {
                console.log(error);
                const errorMessage = errorHandler(error.response.data.error)
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: errorMessage,
                    showConfirmButton: false,
                    timer: 3500,
                });
            })
    };

    createNewBinanceAccount = () => {
        const token = window.localStorage.getItem('token');
        const reqBody = {
            label: this.state.newBinanceAccountLabel,
            api_key: this.state.newBinanceAccountAPIKey,
            secret_key: this.state.newBinanceAccountSecretKey,
        };

        Axios.post('https://api.mobininvestment.com/binance/', reqBody, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'حساب جدید با موفقیت ایجاد شد',
                        showConfirmButton: false,
                        timer: 3500,
                    });

                    this.fetchBinanceAccounts();
                }
            })
            .catch(error => {
                console.log(error);
                const errorMessage = errorHandler(error.response.data.error)
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: errorMessage,
                    showConfirmButton: false,
                    timer: 3500,
                });
            })
    };

    walletsMapper = () => {
        const { walletsList } = this.state;

        if (walletsList.length !== 0) {
            return (
                walletsList.map((item, index) => {
                    return (
                        <div className={styles.card_wallets} key={index}>
                            <div className={styles.card_wallets_titleWrapper}>
                                <img src={walletIcon} alt="wallet-icon" />
                                <span>{item.label}</span>
                            </div>
                            <div className={styles.card_wallets_iconWrapper}>
                                <span>{item.label}</span>
                                <br />
                                <span>{item.currency}</span>
                            </div>
                        </div>
                    )
                })
            )
        }
    };

    accountsMapper = () => {
        const { binanceList } = this.state;

        if (binanceList.length !== 0) {
            return (
                binanceList.map((item, index) => {
                    return (
                        <div className={styles.card_wallets} key={index}>
                            <div className={styles.card_wallets_titleWrapper}>
                                <img src={binanceIcon} alt="wallet-icon" />
                                <span>{item.label}</span>
                            </div>
                            <div className={styles.card_wallets_iconWrapper}>

                            </div>
                        </div>
                    )
                })
            )
        }
    };

    render() {
        return (
            <div className={styles.container}>
                {/* <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>کیف پول ها</span><span className={styles.hr_line}></span>
                    </div>
                    <div className={styles.card_wrapper}>
                        <div className="d-flex">
                            <div className={styles.card} onClick={() => this.setState({ createNewWalletForm: true })}>
                                <img src={addWallet} alt="" />
                                <span>ایجاد کیف پول جدید</span>
                            </div>
                            {this.walletsMapper()}
                        </div>
                    </div>
                    <div className={this.state.createNewWalletForm ? styles.detailbox : 'd-none'}>
                        <div className="d-flex align-items-center">
                            <span className={styles.detailbox_title}>ایجاد کیف پول جدید</span><span className={styles.hr_line}></span>
                        </div>
                        <div className="row pt-5">
                            <div className="col-12 col-md-3">
                                <CustomInput
                                    type='text'
                                    placeholder='لیبل'
                                    inputValue={this.state.newWalletLabel}
                                    handleChangeInput={this.handleNewWalletLabelInput}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <CustomInput
                                    type='text'
                                    placeholder='آدرس کیف پول'
                                    inputValue={this.state.newWalletAddress}
                                    handleChangeInput={this.handleNewWalletAddressInput}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <select
                                    className="form-select form-select-lg"
                                    aria-label="Default select example"
                                    onChange={(e) => this.setState({ newWalletCurrency: e.target.value })}
                                >
                                    <option selected>انتخاب رمز ارز</option>
                                    {this.currencySelectMapper()}
                                </select>
                            </div>
                            <div className="col-12 col-md-3 pt-2 pt-md-0">
                                <button
                                    className={styles.register_btn}
                                    onClick={this.createNewWallet}
                                >
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>حساب های بایننس</span><span className={styles.hr_line}></span>
                    </div>
                    <div className={styles.card_wrapper}>
                        <div className="d-flex">
                            <div className={styles.card} onClick={() => this.setState({ newBinanceAccountForm: true })}>
                                <img src={addBinance} alt="binance-icon" />
                                <span>ایجاد حساب جدید</span>
                            </div>
                            {this.accountsMapper()}
                        </div>
                    </div>
                    <div className={this.state.newBinanceAccountForm ? styles.detailbox : 'd-none'}>
                        <div className="d-flex align-items-center">
                            <span className={styles.detailbox_title}>ایجاد حساب جدید</span><span className={styles.hr_line}></span>
                        </div>
                        <div className="row pt-5">
                            <div className="col-12 col-md-3">
                                <CustomInput
                                    type='text'
                                    placeholder='لیبل'
                                    inputValue={this.state.newBinanceAccountLabel}
                                    handleChangeInput={this.handleNewBinanceLabelInput}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <CustomInput
                                    type='text'
                                    placeholder='کلید اصلی'
                                    inputValue={this.state.newBinanceAccountAPIKey}
                                    handleChangeInput={this.handleNewBinanceAPIKeyInput}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <CustomInput
                                    type='text'
                                    placeholder='کلید خصوصی'
                                    inputValue={this.state.newBinanceAccountSecretKey}
                                    handleChangeInput={this.handleNewBinanceSecretKeyInput}
                                />
                            </div>
                            <div className="col-12 col-md-3 pt-2 pt-md-0">
                                <button
                                    className={styles.register_btn}
                                    onClick={this.createNewBinanceAccount}
                                >
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleNewWalletLabelInput = (value: string) => {
        this.setState({ newWalletLabel: value });
    };

    private handleNewWalletAddressInput = (value: string) => {
        this.setState({ newWalletAddress: value });
    };

    private handleNewBinanceLabelInput = (value: string) => {
        this.setState({ newBinanceAccountLabel: value });
    };

    private handleNewBinanceAPIKeyInput = (value: string) => {
        this.setState({ newBinanceAccountAPIKey: value });
    };

    private handleNewBinanceSecretKeyInput = (value: string) => {
        this.setState({ newBinanceAccountSecretKey: value });
    };
}

export default WalletManagement;
