import jmoment from 'jalali-moment';

export const localeDate = (date, format) => {
    const getFormat = type => {
        return {
            fullDate: ' HH:mm:ss YYYY/MM/DD',
            shortDate: ' HH:mm YYYY/MM/DD',
            time: 'HH:mm:ss',
            date: 'YYYY/MM/DD',
        }[type];
    };
    const formatDisplay = getFormat(format);

    return jmoment(date).locale('fa').format(formatDisplay);
};
