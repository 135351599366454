import { useState, useEffect } from 'react';
import styles from './SideBar.module.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { sidebarItems, Items } from '../../constants';
import { withRouter } from 'react-router-dom';
import { setUserRoleAction } from '../../state/actions';
import { useDispatch } from 'react-redux';

const logo = require('../../assets/images/logo.png').default;
const exitIcon = require('../../assets/icons/icon-exit.svg').default;

function SideBarContainer(props: any) {
    const [items, setItems] = useState<Items[]>();
    const [selectedItem, setSelectedItem] = useState(0);
    const dispatch = useDispatch();
    const reduxUserRole = useTypedSelector((state) => state.userRole);
    const userRole = window.localStorage.getItem('role') || reduxUserRole;

    useEffect(() => {
        setItems(sidebarItems(userRole));
    }, []);

    const itemsMapper = () => {
        if (items) {
            return (
                items.map((item, index) => {
                    return (
                        <div
                            onClick={() => itemClick(index, item.route)}
                            className={selectedItem === index ? styles.selected_item : styles.item}
                            key={index}
                        >
                            <i className={item.icon} />
                            {item.title}
                        </div>
                    )
                })
            );
        }
    };

    const mobileItemsMapper = () => {
        if (items) {
            return (
                items.map((item, index) => {
                    return (
                        <div
                            className={selectedItem === index ? styles.mobile_item_selected : styles.mobile_item}
                            onClick={() => itemClick(index, item.route)}
                            key={index}
                        >
                            <i className={item.icon} />
                        </div>
                    )
                })
            );
        }
    };

    const itemClick = (index: number, route: string): void => {
        if (route === 'log-out') {
            exitFunc();
        } else {
            setSelectedItem(index);
            props.history.push(route);
        }
    };

    const exitFunc = () => {
        window.localStorage.clear();
        dispatch(setUserRoleAction('root'));
        props.history.push('/');
        setTimeout(() => {
            window.location.reload();
        }, 200);
    };

    return (
        <>
            <div className={styles.container}>
                <div className="text-center pt-3">
                    <img src={logo} alt="logo" />
                </div>
                <div className="mt-5">
                    {itemsMapper()}
                </div>
            </div>
            <div className="d-md-none">
                <div className={styles.container_mobile}>
                    {mobileItemsMapper()}
                </div>
            </div>
        </>
    );
}

export const SideBar = withRouter(SideBarContainer);
