import React, { Component } from 'react';
import { CustomInput } from '../../components/CustomInput';
import styles from './style.module.scss';
import Swal from 'sweetalert2';
import Axios from 'axios';
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { localeDate } from '../../helpers/localeDate';

const deleteIcon = require('../../assets/icons/icon-delete.svg').default;
const detailIcon = require('../../assets/icons/icon-detail.svg').default;
const addUserIcon = require('../../assets/icons/icon-user-alt.svg').default;

class UserManagement extends Component {
    state = {
        searchInput: '',
        initMemberstableData: [],
        registeredMemberstableData: null,
        pendingMemberstableData: null,
        addUserFormDisplay: false,
        newUserFullName: '',
        newUserEmail: '',
        newUserPassword: '',
        newUserConfirmPassword: '',
        newUserPhone: '',
        newUserInterestSplit: '',
        selectedPendingUserId: null,
        pendingUserInterestSplit: 0,
        selectedUserFullName: '---',
        selectedUserAsset: '---',
        intervalData: null,
        depositsTableData: null,
        withdrawsTableData: null,
    };

    componentDidMount() {
        this.updateUsersList();
    };

    updateUsersList = () => {
        const token = window.localStorage.getItem('token');

        Axios.get('https://api.mobininvestment.com/users/', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.data.length !== 0) {
                    this.setState({ initMemberstableData: Response.data, registeredMemberstableData: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    render() {
        const {
            intervalData,
            selectedUserFullName,
            selectedUserAsset
        } = this.state;

        return (
            <div className={styles.container}>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>کاربران فعال</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-4">
                        <CustomInput
                            name='user_management_search'
                            id='user_management_search_input'
                            type='text'
                            placeholder='جستجو'
                            inputValue={this.state.searchInput}
                            handleChangeInput={this.handleSearchInput}
                            className={styles.input}
                        />
                        <button
                            className={styles.addmember_btn}
                            onClick={() => this.setState({ addUserFormDisplay: true })}
                        >
                            <img src={addUserIcon} alt="" />
                            <span className="d-none d-lg-inline-block">
                                &nbsp; افزودن کاربر
                            </span>
                        </button>
                    </div>
                    <div className={styles.detailbox_table_wrapper}>
                        <table>
                            <thead>
                                <tr>
                                    <th>آی‌دی کاربر</th>
                                    <th>نام کاربری</th>
                                    <th>دسترسی</th>
                                    <th>میزان سرمایه</th>
                                    <th>وضعیت</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.activeMemebersTable()}
                            </tbody>
                        </table>
                    </div>
                    <div className={this.state.addUserFormDisplay ? styles.addmember_formbox : styles.display_none}>
                        <div className="row mb-4"><span>افزودن کاربر</span></div>
                        <div className="row mb-3">
                            <div className="col-6 col-md-4">
                                <CustomInput
                                    type='text'
                                    placeholder='نام و نام خانوادگی'
                                    inputValue={this.state.newUserFullName}
                                    handleChangeInput={this.handleNewUserFullNameInput}
                                />
                            </div>
                            <div className="col-6 col-md-4">
                                <CustomInput
                                    type='text'
                                    placeholder='آدرس پست الکترونیکی'
                                    inputValue={this.state.newUserEmail}
                                    handleChangeInput={this.handleNewUserEmailInput}
                                />
                            </div>
                            <div className="col-6 col-md-4">
                                <CustomInput
                                    type='text'
                                    placeholder='شماره تماس'
                                    maxLength={11}
                                    inputValue={this.state.newUserPhone}
                                    handleChangeInput={this.handleNewUserPhoneInput}
                                />
                            </div>
                            <div className="col-6 col-md-4">
                                <CustomInput
                                    type='password'
                                    placeholder='رمز عبور'
                                    inputValue={this.state.newUserPassword}
                                    handleChangeInput={this.handleNewUserPasswordInput}
                                />
                            </div>
                            <div className="col-6 col-md-4">
                                <CustomInput
                                    type='password'
                                    placeholder='تکرار رمز عبور'
                                    inputValue={this.state.newUserConfirmPassword}
                                    handleChangeInput={this.handleNewUserConfirmPasswordInput}
                                />
                            </div>
                            {/* <div className="col-2 ps-0">
                                <CustomInput
                                    type='text'
                                    placeholder='درصد سود'
                                    inputValue={this.state.newUserInterestSplit}
                                    handleChangeInput={this.handleNewUserInterestSplitInput}
                                />
                            </div> */}
                            <div className="col-6 col-md-4 pe-1">
                                <button
                                    disabled={this.addUserSubmitButtonState()}
                                    className={styles.register_btn}
                                    onClick={this.addUser}
                                >
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center mb-4">
                        <span className={styles.detailbox_title}>کاربران غیرفعال</span><span className={styles.hr_line}></span>
                    </div>
                    <div className={styles.detailbox_table_wrapper}>
                        <table>
                            <thead>
                                <tr>
                                    <th>آی‌دی کاربر</th>
                                    <th>نام کاربری</th>
                                    <th>دسترسی</th>
                                    <th>میزان سرمایه</th>
                                    <th>وضعیت</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.deactiveMemebersTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="interestSplitModal"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="interestSplitModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h6>لطفا درصد سود را برای کاربر مورد نظر تعیین نمایید:</h6>
                                <CustomInput
                                    type='text'
                                    placeholder='درصد سود'
                                    className='text-dark bg-light'
                                    inputValue={this.state.pendingUserInterestSplit}
                                    handleChangeInput={this.handlePendingUserInterestSplitInput}
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={this.activatePendingUser}
                                    data-bs-dismiss="modal"
                                >
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="userDetailModal"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="userDetailModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog  modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex align-items-center mb-4">
                                    <span className={styles.detailbox_title}>{selectedUserFullName}</span><span className={styles.hr_line}></span>
                                </div>

                                <div className="row">
                                    <div className="col-6 col-md-3 text-center">
                                        <span className={styles.detailModalQuickInfoTitle}>کل سرمایه فعلی کاربر</span>
                                        <br />
                                        <span className={styles.detailModalQuickInfoData}>{selectedUserAsset}</span>
                                    </div>
                                    <div className="col-6 col-md-3 text-center">
                                        <span className={styles.detailModalQuickInfoTitle}>سرمایه کاربر ابتدای دوره</span>
                                        <br />
                                        <span className={styles.detailModalQuickInfoData}>{intervalData ? intervalData[0].initial_value : '---'}</span>
                                    </div>
                                    <div className="col-6 col-md-3 text-center">
                                        <span className={styles.detailModalQuickInfoTitle}>سود کاربر در آخرین دوره</span>
                                        <br />
                                        <span className={styles.detailModalQuickInfoData}>{intervalData ? intervalData[0].interest_in : '---'}</span>
                                    </div>
                                    <div className="col-6 col-md-3 text-center">
                                        <span className={styles.detailModalQuickInfoTitle}>سود سامانه در آخرین دوره</span>
                                        <br />
                                        <span className={styles.detailModalQuickInfoData}>{intervalData ? intervalData[0].system_interest_in : '---'}</span>
                                    </div>
                                </div>

                                <div className={styles.interval}>
                                    <div className="d-flex align-items-center">
                                        <span className={styles.interval_title}>سابقه دوره های سرمایه گذاری</span><span className={styles.hr_line}></span>
                                    </div>
                                    <div className="pt-4">
                                        {this.intervalTable()}
                                    </div>
                                </div>

                                <div className={styles.interval}>
                                    <div className="d-flex align-items-center">
                                        <span className={styles.detailbox_title}>واریز / برداشت</span><span className={styles.hr_line}></span>
                                    </div>
                                    <div className="pt-3">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">واریز</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">برداشت</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>تاریخ</th>
                                                                <th>مقدار</th>
                                                                <th>وضعیت</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.depositsTable()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>تاریخ</th>
                                                                <th>مقدار</th>
                                                                <th>وضعیت</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.withdrawsTable()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    detailFunc = (id: number, fullName: string, asset: number): void => {
        const token = window.localStorage.getItem('token');
        this.setState({
            intervalData: null,
            depositsTableData: null,
            withdrawsTableData: null,
            selectedUserFullName: fullName,
            selectedUserAsset: asset
        });

        Axios.get(`https://api.mobininvestment.com/users/${id}/interval`, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.data.length !== 0) {
                    this.setState({ intervalData: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            })

        Axios.get(`https://api.mobininvestment.com/accounting/deposits?user_id=${id}`, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.data.length !== 0) {
                    this.setState({ depositsTableData: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            })

        Axios.get(`https://api.mobininvestment.com/accounting/withdraws?user_id=${id}`, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.data.length !== 0) {
                    this.setState({ withdrawsTableData: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    intervalTable = () => {
        const { intervalData } = this.state;

        if (intervalData && intervalData.length !== 0) {
            return (
                <table>
                    <thead>
                        <tr>
                            <th>تاریخ شروع دوره</th>
                            <th>مجموع سود کاربر پیش از دوره</th>
                            <th>سرمایه ابتدا  دوره</th>
                            <th>سود کاربر در دوره</th>
                            <th>سود سامانه در دوره</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            intervalData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{localeDate(item.created_at, 'date')}</td>
                                        <td>{item.interest_start}</td>
                                        <td>{item.initial_value}</td>
                                        <td>{item.interest_in}</td>
                                        <td>{item.system_interest_in}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        } else {
            return (
                <div className="text-center">
                    <span>اطلاعاتی موجود نمی باشد</span>
                </div>
            )
        }
    };

    depositsTable = () => {
        const { depositsTableData } = this.state;

        if (depositsTableData && depositsTableData.length !== 0) {
            return (
                <React.Fragment>
                    {
                        depositsTableData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{localeDate(item.created_at, 'shortDate')}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.done === true ? 'انجام شده' : 'انجام نشده'}</td>
                                </tr>
                            )
                        })
                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={3}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    }

    withdrawsTable = () => {
        const { withdrawsTableData } = this.state;

        if (withdrawsTableData && withdrawsTableData.length !== 0) {
            return (
                <React.Fragment>
                    {
                        withdrawsTableData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{localeDate(item.created_at, 'shortDate')}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.done === true ? 'انجام شده' : 'انجام نشده'}</td>
                                </tr>
                            )
                        })
                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={3}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    }

    activeMemebersTable = () => {
        const { registeredMemberstableData } = this.state;

        if (registeredMemberstableData) {
            return (
                <React.Fragment>
                    {
                        registeredMemberstableData.map((item, index) => {
                            if (item.state === 'active') {
                                return (<tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.fullname}</td>
                                    <td>{this.roleTitle(item.role)}</td>
                                    <td>{item.asset || '----'}</td>
                                    <td>{this.userState(item.state)}</td>
                                    <td>
                                        <div className="d-flex flex-column align-items-center flex-md-row justify-content-center">
                                            {(item.role !== 'admin') && (
                                                <>
                                                    <img
                                                        src={detailIcon}
                                                        onClick={() => this.detailFunc(item.id, item.fullname, item.asset)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#userDetailModal"
                                                        alt="" />
                                                    <img src={deleteIcon} onClick={() => this.deleteFunc(item.id)} alt="" />
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input cursor-pointer"
                                                            type="checkbox"
                                                            id={`flexSwitchCheckDefault${item.id}`}
                                                            onClick={() => this.changeStateFunc(item.id, item.state)}
                                                            checked={item.state === 'active'}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>)
                            }
                        })
                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={6}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    }

    deactiveMemebersTable = () => {
        const { registeredMemberstableData } = this.state;

        if (registeredMemberstableData) {
            return (
                <React.Fragment>
                    {
                        registeredMemberstableData.map((item, index) => {
                            if (item.state === 'pending' || item.state === 'banned') {
                                return (<tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.fullname}</td>
                                    <td>{this.roleTitle(item.role)}</td>
                                    <td>{item.asset || '----'}</td>
                                    <td>{this.userState(item.state)}</td>
                                    <td>
                                        <div className="d-flex flex-column align-items-center flex-md-row justify-content-center">
                                            <img
                                                src={detailIcon}
                                                onClick={() => this.detailFunc(item.id, item.fullname, item.asset)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#userDetailModal"
                                                alt=""
                                            />
                                            <img src={deleteIcon} onClick={() => this.deleteFunc(item.id)} alt="" />
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input cursor-pointer"
                                                    type="checkbox"
                                                    id={`flexSwitchCheckDefault${item.id}`}
                                                    onClick={() => this.activateFunc(item.id, item.state)}
                                                    checked={false}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>)
                            }
                        })
                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={6}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    };

    handleNewUserFullNameInput = (value: string) => {
        this.setState({ newUserFullName: value });
    };

    handleNewUserEmailInput = (value: string) => {
        this.setState({ newUserEmail: value });
    };

    handleNewUserPhoneInput = (value: string) => {
        this.setState({ newUserPhone: value });
    };

    handleNewUserPasswordInput = (value: string) => {
        this.setState({ newUserPassword: value });
    };

    handleNewUserConfirmPasswordInput = (value: string) => {
        this.setState({ newUserConfirmPassword: value });
    };

    handleNewUserInterestSplitInput = (value: string) => {
        this.setState({ newUserInterestSplit: value });
    };

    handlePendingUserInterestSplitInput = (value: string) => {
        this.setState({ pendingUserInterestSplit: value });
    };

    handleSearchInput = (value: string) => {
        const searchResult = this.state.initMemberstableData.filter((item) => {
            return item.fullname.includes(value);
        });

        this.setState({ searchInput: value, registeredMemberstableData: searchResult });
    };

    addUserSubmitButtonState = () => {
        const state =
            this.state.newUserFullName === '' ||
            this.state.newUserEmail === '' ||
            this.state.newUserPhone === '' ||
            this.state.newUserPassword === '' ||
            this.state.newUserConfirmPassword === ''
        return state;
    };

    addUser = () => {
        const token = window.localStorage.getItem('token');
        const reqBody = {
            state: "active",
            fullname: this.state.newUserFullName,
            email: this.state.newUserEmail,
            phone: this.state.newUserPhone,
            password: this.state.newUserPassword,
            interest_split: this.state.newUserInterestSplit
        };

        if (this.state.newUserPassword === this.state.newUserConfirmPassword) {
            Axios.post('https://api.mobininvestment.com/users/', reqBody, { headers: { Authorization: `bearer ${token}` } })
                .then(Response => {
                    if (Response.status === 200 || Response.status === 201) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            text: 'کاربر جدید با موفقیت ثبت شد',
                            showConfirmButton: false,
                            timer: 3500,
                        });

                        this.updateUsersList();
                        this.setState({
                            newUserFullName: '',
                            newUserEmail: '',
                            newUserPassword: '',
                            newUserConfirmPassword: '',
                            newUserPhone: '',
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        text: 'درخواست با خطا مواجه شد لطفا مجددا تلاش نمایید',
                        showConfirmButton: false,
                        timer: 3500,
                    });
                })
        } else {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                text: 'رمز عبور مطابقت ندارد',
                showConfirmButton: false,
                timer: 3500,
            });
        }
    };

    roleTitle = (role: string): string => {
        switch (role) {
            case 'trader':
                return 'معامله گر';
            case 'admin':
                return 'ادمین';
            default:
                return 'کاربر';
        }
    };

    userState = (state: string): string => {
        switch (state) {
            case 'banned':
                return 'غیرفعال';
            case 'pending':
                return 'در انتظار تایید';
            default:
                return 'فعال';
        }
    };

    deleteFunc = (id: number) => {
        const token = window.localStorage.getItem('token');

        Axios.delete(`https://api.mobininvestment.com/users/${id}`, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 201 || Response.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'کاربر مورد نظر با موفقیت حذف شد',
                        showConfirmButton: false,
                        timer: 3500,
                    });

                    this.updateUsersList();
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    activateFunc = (id: number, state: string) => {
        if (state === 'pending') {
            this.setState({ selectedPendingUserId: id });

            // toggle interestSplit modal
            const modal = new bootstrap.Modal(document.getElementById('interestSplitModal'));
            modal.toggle();
        } else {
            this.changeStateFunc(id, state);
        }
    };

    activatePendingUser = () => {
        const { selectedPendingUserId } = this.state;
        this.changeStateFunc(selectedPendingUserId, 'pending');
    };

    changeStateFunc = (id: number, state: string): void => {
        const token = window.localStorage.getItem('token');
        let reqBody;
        let text;

        switch (state) {
            case 'pending': {
                const interest_split = this.state.pendingUserInterestSplit;
                reqBody = { state: "active", interest_split };
                text = 'کاربر مورد نظر با موفقیت فعال شد';
                break;
            };
            case 'banned': {
                reqBody = { state: "active", };
                text = 'کاربر مورد نظر با موفقیت فعال شد';
                break;
            };
            default: {
                reqBody = { state: "banned" };
                text = 'کاربر مورد نظر با موفقیت غیرفعال شد';
                break;
            };
        };

        Axios.patch(`https://api.mobininvestment.com/users/${id}`, reqBody, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 201 || Response.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: text,
                        showConfirmButton: false,
                        timer: 3500,
                    });

                    this.updateUsersList();
                }
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'مشکلی به وجود آمده است. لطفا دوباره تلاش کنید',
                    showConfirmButton: false,
                    timer: 3500,
                });
            })
    };
}

export default UserManagement;
