import { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import Axios from 'axios';

const avatar = require('../../assets/icons/icon-account-circle.svg').default;

export function Header() {
    const [fullname, setFullname] = useState('...');

    useEffect(() => {
        const token = window.localStorage.getItem('token');
        if (token) {
            Axios.get('https://api.mobininvestment.com/members/profile', { headers: { Authorization: `bearer ${token}` } })
                .then(Response => {
                    if (Response.status === 200 || Response.status === 201) {
                        console.log(Response.data);

                        setFullname(Response.data.fullname);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, []);

    return (
        <div className={styles.container}>
            <img src={avatar} alt="" />
            &nbsp;
            <span className="mt-1">{fullname}</span>
        </div>
    )
}