import { Route, Redirect } from 'react-router-dom';
import SignIn from '../../screens/SignInScreen';
import SignUp from '../../screens/SignUpScreen';
import ForgetPassword from '../../screens/ForgetPasswordScreen';
import ResetPassword from '../../screens/ResetPasswordScreen';

function RootRoutes() {
    return (
        <>
            <Route path="/" exact component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route path="/forgetpassword" component={ForgetPassword} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route render={() => <Redirect to="/" />} />
        </>
    );
}

export default RootRoutes;