import React, { Component } from 'react';
import styles from './style.module.scss';
import { CustomInput } from '../../components/CustomInput';
import Swal from 'sweetalert2';
import { localeDate } from '../../helpers/localeDate';
import Axios from 'axios';
import Spinner from '../../components/Spinner';
import { withdrawNetworkList } from '../../constants';

class Accounting extends Component {
    state = {
        usersData: null,
        historyTab: 'deposits',
        depositAmount: '',
        withdrawAmount: '',
        depositsTableData: [],
        withdrawsTableData: [],
        historyTableData: null,
        selectedUserIdForDeposit: null,
        selectedUserIdForWithdraw: null,
        editModalShow: false,
        depositId: '',
        binanceId: '',
        userId: '',
        share: '',
        split: '',
        fetching: false,
        users: [],
        deposits: [],
        withdraws: [],
        fetchingDeposits: true,
        fetchingWithdraws: true,
        createWithdrawModalShow: false,
        requestingNewWithdraw: false,
        withdrawMaxAmount: '',
        newWithdrawForm: {
            amount: '',
            address: '',
            binance_account_id: '',
            deposit_id: '',
            network: '',
            code: '',
            max: false,
        },
        totalWorth: 0,
    };

    componentDidMount() {
        const token = window.localStorage.getItem('token');

        // Axios.get('https://api.mobininvestment.com/users/', { headers: { Authorization: `bearer ${token}` } })
        //     .then(Response => {
        //         if (Response.data.length !== 0) {
        //             this.setState({ usersData: Response.data });
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })

        // this.withdrawsFetch();
        // this.depositsFetch();

        this.getDeposits();
        this.getWithdraws();
    };

    depositsFetch = () => {
        const token = window.localStorage.getItem('token');

        Axios.get('https://api.mobininvestment.com/accounting/deposits', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.data.length !== 0) {
                    this.setState({ depositsTableData: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    withdrawsFetch = () => {
        const token = window.localStorage.getItem('token');

        Axios.get('https://api.mobininvestment.com/accounting/withdraws', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.data.length !== 0) {
                    this.setState({ withdrawsTableData: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    getDeposits = () => {
        const token = window.localStorage.getItem('token');
        this.setState({ fetchingDeposits: true });
        Axios.get('https://api.mobininvestment.com/deposits/', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.data.length !== 0) {
                    this.setState({
                        deposits: Response.data,
                        fetchingDeposits: false,
                    });

                    Response.data.forEach(i => {
                        i.user_id && this.getUserData(i.user_id)
                    })

                    let totalWorth = 0;
                    Response.data.forEach(i => {
                        totalWorth += +i.amount
                    })
                    this.setState({ totalWorth: totalWorth.toFixed(3) })
                }
            })
            .catch(error => {
                this.setState({ fetchingDeposits: false });
                console.log(error);
            })
    };

    getWithdraws = () => {
        const token = window.localStorage.getItem('token');
        this.setState({ fetchingWithdraws: true });
        Axios.get('https://api.mobininvestment.com/withdraws/', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.data.length !== 0) {
                    this.setState({
                        withdraws: Response.data,
                        fetchingWithdraws: false,
                    });
                    Response.data.forEach(i => {
                        i.user_id && this.getUserData(i.user_id)
                    })
                }
            })
            .catch(error => {
                this.setState({ fetchingWithdraws: false });
                console.log(error);
            })
    };

    getUserData = async (id: number) => {
        const token = window.localStorage.getItem('token');
        try {
            const res = await Axios.get(`https://api.mobininvestment.com/users/${id}`, { headers: { Authorization: `bearer ${token}` } });
            this.setState({ users: [...this.state.users, res.data] });
        } catch (e) {
            console.warn(e);
        }
    };

    updateDeposit = () => {
        const token = window.localStorage.getItem('token');
        const params = {
            share: this.state.share,
            user_id: this.state.userId,
            split: this.state.split,
        };

        this.setState({ fetching: true });

        Axios.patch(`https://api.mobininvestment.com/deposits/${this.state.depositId}`, params, {
            headers: { Authorization: `bearer ${token}` },
        })
            .then(() => {
                this.setState({
                    depositId: '',
                    userId: '',
                    binanceId: '',
                    split: '',
                    share: '',
                    fetching: false,
                    editModalShow: false,
                });

                const closeBTN = document.getElementById('un-edit-modal-close-btn');
                closeBTN?.click();

                this.getDeposits();
            })
            .catch(error => {
                this.setState({ fetching: false });
                console.log(error);
            })
    };

    createNewWithdrawRequest = () => {
        const token = window.localStorage.getItem('token');
        this.setState({ requestingNewWithdraw: true });
        const body = { ...this.state.newWithdrawForm };

        Axios.post(`https://api.mobininvestment.com/withdraws/`, body, {
            headers: { Authorization: `bearer ${token}` },
        })
            .then(() => {
                this.setState({
                    requestingNewWithdraw: false,
                    createWithdrawModalShow: false,
                    withdrawMaxAmount: '',
                    newWithdrawForm: {
                        amount: '',
                        address: '',
                        binance_account_id: '',
                        deposit_id: '',
                        network: null,
                        code: null,
                        max: false,
                    }
                });
                this.getWithdraws();

                const closeBTN = document.getElementById('un-withdraw-modal-close-btn');
                closeBTN?.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: 'درخواست مورد نظر با موفقیت ثبت شد',
                    showConfirmButton: false,
                    timer: 3500,
                });
            })
            .catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'درخواست با خطا مواجه شد',
                    showConfirmButton: false,
                    timer: 3500,
                });

                this.setState({ requestingNewWithdraw: false });
                console.log(error);
            })
    };

    getWithdrawMaxAmount = async () => {
        const token = window.localStorage.getItem('token');
        try {
            const res = await Axios.get(`https://api.mobininvestment.com/deposits/${this.state.newWithdrawForm.deposit_id}/max`, { headers: { Authorization: `bearer ${token}` } });
            this.setState({ withdrawMaxAmount: res.data.max });
        } catch (e) {
            console.warn(e);
        }
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.detailbox}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                        <span>ارزش کل: </span>
                        <span>USDT {this.state.totalWorth} </span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>لیست واریز</span><span className={styles.hr_line}></span>
                    </div>
                    {/* <div className="pt-5">
                        <ul className="nav nav-tabs custom-nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button className="nav-link active" onClick={() => this.setState({ historyTab: 'deposits' })} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">افزایش حساب اصلی</button>
                            </li>
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button className="nav-link" onClick={() => this.setState({ historyTab: 'withdraws' })} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">کاهش حساب اصلی</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active pt-5" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className="row">
                                    <div className="col-12 col-md-4 pt-2">
                                        <select
                                            className="form-select form-select-lg"
                                            aria-label="Default select example"
                                            onChange={(e) => this.userSelect('deposit', e)}
                                        >
                                            <option selected>انتخاب حساب کاربر</option>
                                            {this.usersSelectMapper()}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <CustomInput
                                            type='text'
                                            placeholder='مقدار USDT'
                                            inputValue={this.state.depositAmount}
                                            handleChangeInput={this.handleDepostiInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button
                                            // disabled={this.buttonState()}
                                            onClick={this.deposit}
                                            className={styles.button}
                                        >
                                            ثبت
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade pt-5" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="row">
                                    <div className="col-12 col-md-4 pt-2">
                                        <select
                                            className="form-select form-select-lg"
                                            aria-label="Default select example"
                                            onChange={(e) => this.userSelect('withdraw', e)}
                                        >
                                            <option selected>انتخاب حساب کاربر</option>
                                            {this.usersSelectMapper()}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <CustomInput
                                            type='text'
                                            placeholder='مقدار USDT'
                                            inputValue={this.state.withdrawAmount}
                                            handleChangeInput={this.handleWithdrawInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button
                                            // disabled={this.buttonState()}
                                            onClick={this.withdraw}
                                            className={styles.button}
                                        >
                                            ثبت
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="mt-3">
                        <div className={styles.detailbox_table_wrapper}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>نام کاربر</th>
                                        <th>مقدار</th>
                                        <th>تاریخ ثبت</th>
                                        <th>وضعیت</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.historyListTable()}
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                    <div className={styles.detailbox_table_wrapper}>
                        <table>
                            <thead>
                                <tr>
                                    <th>نام کاربر</th>
                                    <th>شناسه واریز</th>
                                    <th>زمان واریز</th>
                                    <th>آی‌دی بایننس</th>
                                    <th>رمز ارز</th>
                                    <th>مقدار</th>
                                    <th>سهم از پرتفوی</th>
                                    <th>سهم سامانه از پرتفوی</th>
                                    <th>ارزش تخمینی</th>
                                    <th>ویرایش</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.fetchingDeposits ?
                                    <tr>
                                        <td colSpan={10}><Spinner color='#3ea9c2' /></td>
                                    </tr> :
                                    this.depositsTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>لیست برداشت‌</span><span className={styles.hr_line}></span>
                    </div>
                    <div className={styles.detailbox_action}>
                        <button
                            //  onClick={() => this.setState({ createWithdrawModalShow: true })}
                            data-bs-toggle="modal"
                            data-bs-target="#unWithdrawModal"
                        >
                            <b>+</b><span>برداشت جدید</span>
                        </button>
                    </div>
                    <div className={styles.detailbox_table_wrapper}>
                        <table>
                            <thead>
                                <tr>
                                    <th>نام کاربر</th>
                                    <th>مقدار</th>
                                    <th>آی‌دی حساب بایننس</th>
                                    <th>شماره واریز</th>
                                    <th>وضعیت</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.fetchingWithdraws ?
                                    <tr>
                                        <td colSpan={5}> <Spinner color='#3ea9c2' /></td>
                                    </tr> :
                                    this.withdrawsTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.editModal()}
                {this.createWithdrawModal()}
            </div>
        );
    }

    openEditModal = (item: any) => {
        this.setState({
            depositId: item.id,
            userId: item.user_id,
            binanceId: item.binance_id,
            split: item.split,
            share: item.share,
            editModalShow: true,
        });
    };

    depositsTable = () => {
        const data = this.state.deposits;
        if (data.length > 0) {
            return (
                <>
                    {data.map(item => {
                        const userFullname = this.state.users.find(i => i.id === item.user_id)?.fullname || '---'
                        return (<tr key={item.id}>
                            <td>{userFullname}</td>
                            <td>{item.id}</td>
                            <td>{item.created_at?.slice(0, 10)}</td>
                            <td>{item.binance_id}</td>
                            <td>{item.coin}</td>
                            <td>{item.amount ? parseFloat(item.amount).toFixed(3) : '----'}</td>
                            <td>{item.share ? `${(parseFloat(item.share) * 100).toFixed(2)}%` : '----'}</td>
                            <td>{item.split ? `${(parseFloat(item.split) * 100).toFixed(2)}%` : '----'}</td>
                            <td>{parseFloat(item.estimated_worth).toFixed(3)}</td>
                            <td>
                                <i
                                    onClick={() => this.openEditModal(item)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#unEditModal"
                                    className='fas fa-edit'
                                    style={{ cursor: 'pointer' }}
                                />
                            </td>
                        </tr>)
                    })}
                </>
            );
        } else {
            return (
                <tr>
                    <td colSpan={8}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    }

    withdrawsTable = () => {
        const data = this.state.withdraws;
        if (data.length > 0) {
            return (
                <>
                    {data.map(item => {
                        const userFullname = this.state.users.find(i => i.id === item.user_id)?.fullname || '---'
                        return (<tr key={item.id}>
                            <td>{userFullname}</td>
                            <td>{item.amount}</td>
                            <td>{item.binance_account_id}</td>
                            <td>{item.deposit_id}</td>
                            <td>{item.state === 'completed' ? 'انجام شده' : 'درخواست شده'}</td>
                        </tr>)
                    })}
                </>
            );
        } else {
            return (
                <tr>
                    <td colSpan={8}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    }

    historyListTable = () => {
        const historyTableData = this.state.historyTab === 'deposits' ? this.state.depositsTableData : this.state.withdrawsTableData;

        if (historyTableData) {
            return (
                <React.Fragment>
                    {
                        historyTableData.map((item, index) => {
                            return (<tr key={index}>
                                <td>{item.user.fullname}</td>
                                <td>{item.amount}</td>
                                <td>{localeDate(item.created_at, 'shortDate')}</td>
                                <td>{item.done === true ? 'انجام شده' : 'انجام نشده'}</td>
                            </tr>)
                        })
                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={6}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    };

    editModal = () => {
        return (
            <div
                className="modal fade"
                id="unEditModal"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="unEditModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between py-1" style={{ backgroundColor: '#6a757a' }}>
                            <span >ویرایش حساب</span>
                            <button
                                id='un-edit-modal-close-btn'
                                type="button"
                                className="btn-close m-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className={styles.edit_modal}>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='binance_id'>آی‌دی بایننس</label>
                                    <input
                                        disabled
                                        type="text"
                                        name='binance_id'
                                        value={this.state.binanceId}
                                        onChange={e => this.setState({ binanceId: e.target.value })}
                                    />
                                </div>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='user_id'>آی‌دی کاربر</label>
                                    <input
                                        autoFocus
                                        type="text"
                                        name='user_id'
                                        value={this.state.userId}
                                        onChange={e => this.setState({ userId: e.target.value })}
                                    />
                                </div>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='share'>سهم از پرتفوی</label>
                                    <input
                                        type="text"
                                        name='share'
                                        value={this.state.share}
                                        onChange={e => this.setState({ share: e.target.value })}
                                    />
                                </div>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='split'>سهم سامانه از پرتفوی</label>
                                    <input
                                        type="text"
                                        name='split'
                                        value={this.state.split}
                                        onChange={e => this.setState({ split: e.target.value })}
                                    />
                                </div>
                                <button
                                    className={styles.edit_modal_btn}
                                    disabled={this.state.fetching}
                                    onClick={() => this.updateDeposit()}
                                >
                                    {this.state.fetching ? <Spinner color='#072531' /> : 'ثبت'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    closeCreateWithdrawModal = () => {
        this.setState({
            createWithdrawModalShow: false,
            withdrawMaxAmount: '',
            newWithdrawForm: {
                amount: '',
                address: '',
                binance_account_id: '',
                deposit_id: '',
                network: null,
                code: null,
                max: false,
            }
        });
    };

    createWithdrawModal = () => {
        return (
            <div
                className="modal fade"
                id="unWithdrawModal"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="unWithdrawModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between py-1" style={{ backgroundColor: '#6a757a' }}>
                            <span >ایجاد درخواست برداشت جدید</span>
                            <button
                                id='un-withdraw-modal-close-btn'
                                type="button"
                                className="btn-close m-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={this.closeCreateWithdrawModal}
                            ></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className={styles.edit_modal}>
                                {/* <div className={styles.edit_modal_header}>
                                    <span />
                                    <i
                                        style={{ cursor: 'pointer' }}
                                        className='fas fa-times'
                                        onClick={this.closeCreateWithdrawModal}
                                    />
                                </div> */}
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='network'>شبکه</label>
                                    <select
                                        name='network'
                                        className="form-select"
                                        aria-label="Default select example"
                                        onChange={(e) => this.setState({ newWithdrawForm: { ...this.state.newWithdrawForm, network: e.target.value } })}
                                    >
                                        <option selected value=''>انتخاب شبکه</option>
                                        {withdrawNetworkList.map(i => <option value={i}>{i}</option>)}
                                    </select>
                                </div>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='amount'>مقدار</label>
                                    <input
                                        autoFocus
                                        type="text"
                                        name='amount'
                                        disabled={this.state.newWithdrawForm.max}
                                        value={this.state.newWithdrawForm.amount}
                                        onChange={e => this.setState({ newWithdrawForm: { ...this.state.newWithdrawForm, amount: e.target.value } })}
                                    />
                                </div>
                                <div className="form-check form-switch">
                                    <input
                                        checked={this.state.newWithdrawForm.max}
                                        onClick={() => this.setState({ newWithdrawForm: { ...this.state.newWithdrawForm, amount: '', max: !this.state.newWithdrawForm.max } })}
                                        className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">حداکثر مبلغ قابل برداشت</label>
                                </div>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='address'>آدرس مقصد</label>
                                    <input
                                        type="text"
                                        name='address'
                                        value={this.state.newWithdrawForm.address}
                                        onChange={e => this.setState({ newWithdrawForm: { ...this.state.newWithdrawForm, address: e.target.value } })}
                                    />
                                </div>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='binance_account_id'>حساب بایننس</label>
                                    <input
                                        type="text"
                                        name='binance_account_id'
                                        value={this.state.newWithdrawForm.binance_account_id}
                                        onChange={e => this.setState({ newWithdrawForm: { ...this.state.newWithdrawForm, binance_account_id: e.target.value } })}
                                    />
                                </div>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='deposit_id'>شناسه واریز</label>
                                    <input
                                        type="text"
                                        name='deposit_id'
                                        value={this.state.newWithdrawForm.deposit_id}
                                        onChange={e => this.setState({ newWithdrawForm: { ...this.state.newWithdrawForm, deposit_id: e.target.value } })}
                                    />
                                </div>
                                <div className={styles.edit_modal_get_amount}>
                                    <button
                                        className={styles.edit_modal_get_amount_btn}
                                        disabled={!this.state.newWithdrawForm.deposit_id}
                                        onClick={this.getWithdrawMaxAmount}
                                    >
                                        محاسبه مقدار قابل برداشت
                                    </button>
                                    <span>{this.state.withdrawMaxAmount}</span>
                                </div>
                                <div className={styles.edit_modal_field}>
                                    <label htmlFor='OTP'>شناسه دو عاملی</label>
                                    <input
                                        type="text"
                                        name='OTP'
                                        value={this.state.newWithdrawForm.code}
                                        onChange={e => this.setState({ newWithdrawForm: { ...this.state.newWithdrawForm, code: e.target.value } })}
                                    />
                                </div>
                                <button
                                    disabled={
                                        this.state.requestingNewWithdraw ||
                                        !this.state.newWithdrawForm.code ||
                                        !this.state.newWithdrawForm.address ||
                                        !this.state.newWithdrawForm.network ||
                                        !this.state.newWithdrawForm.deposit_id ||
                                        !this.state.newWithdrawForm.binance_account_id ||
                                        (!this.state.newWithdrawForm.max && !this.state.newWithdrawForm.amount)
                                    }
                                    className={styles.edit_modal_btn}
                                    onClick={() => this.createNewWithdrawRequest()}
                                >
                                    {this.state.requestingNewWithdraw ? <Spinner color='#072531' /> : 'ثبت'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    private handleDepostiInputValue = (value: string) => {
        this.setState({ depositAmount: value });
    };


    private handleWithdrawInputValue = (value: string) => {
        this.setState({ withdrawAmount: value });
    };

    usersSelectMapper = () => {
        const { usersData } = this.state;

        if (usersData) {
            return (
                usersData.map((item, index) => {
                    return (
                        <option key={index} value={item.id}>{item.fullname}</option>
                    )
                })
            )
        }
    }

    userSelect = (type: string, e: React.ChangeEvent<HTMLSelectElement>) => {

        switch (type) {
            case 'deposit': {
                console.log('dep', e.target.value);

                this.setState({ selectedUserIdForDeposit: e.target.value });
                break;
            }
            case 'withdraw': {
                console.log('with', e.target.value);

                this.setState({ selectedUserIdForWithdraw: e.target.value });
                break;
            }
            default: {
                return;
            }
        }
    };

    deposit = () => {
        const token = window.localStorage.getItem('token');
        const reqBody = {
            user_id: this.state.selectedUserIdForDeposit,
            amount: Number(this.state.depositAmount).toFixed(2),
        };

        Axios.post('https://api.mobininvestment.com/accounting/deposits', reqBody, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 201 || Response.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'درخواست مورد نظر با موفقیت ثبت شد',
                        showConfirmButton: false,
                        timer: 3500,
                    });

                    this.depositsFetch();
                }
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'درخواست با خطا مواجه شد',
                    showConfirmButton: false,
                    timer: 3500,
                });
            })

    };

    withdraw = () => {
        const token = window.localStorage.getItem('token');
        const reqBody = {
            user_id: this.state.selectedUserIdForWithdraw,
            amount: Number(this.state.withdrawAmount).toFixed(2),
        };

        Axios.post('https://api.mobininvestment.com/accounting/withdraws', reqBody, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 201 || Response.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'درخواست مورد نظر با موفقیت ثبت شد',
                        showConfirmButton: false,
                        timer: 3500,
                    });

                    this.withdrawsFetch();
                }
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'درخواست با خطا مواجه شد',
                    showConfirmButton: false,
                    timer: 3500,
                });
            })

    };

}

export default Accounting;
