import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { hexColors } from '../../constants';

const renderActiveShape = (data: any) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        share,
    } = data;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "end" : "start";

    return (
        <g>
            <text x={cx} y={cy - 15} dy={8} textAnchor="middle" fill={fill}>
                {payload.symbol}
            </text>
            {/* <text x={cx} y={cy + 6} dy={8} textAnchor="middle" fill={fill}>
                {share}
            </text> */}

            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            {/* <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
            >{`${amount}`}</text> */}
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
            >
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

function DonutChart(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const {
        innerRadius = 90,
        outerRadius = 120,
        width = 400,
        height = 400,
        cx = 220,
        cy = 200,
        data,
        noDataStyle,
    } = props;

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    if (data) {
        return (
            <PieChart width={width} height={height}>
                <Pie
                    animationBegin={700}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    fill="#8884d8"
                    dataKey="share"
                    onMouseEnter={onPieEnter}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={hexColors[index % hexColors.length]} />
                    ))}
                </Pie>
            </PieChart>
        );
    } else {
        return (
            <div className="d-block text-center p-4" style={noDataStyle}>
                <span>اطلاعات موجود نمی باشد.</span>
            </div>
        );
    }
};


export default DonutChart;
