import { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { CustomInput } from '../../components/CustomInput';
import { setUserRoleAction } from '../../state/actions';
import { parseJwt } from '../../helpers/jwt';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Axios from 'axios';

const logo = require('../../assets/images/logo.png').default;
const chainPic = require('../../assets/images/chain.png').default;
const chainPicMobile = require('../../assets/images/chain_mobile.png').default;

function SignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const dispatch = useDispatch();

    const handleUsernameInputValue = (value: string) => {
        setUsername(value);
    };

    const handlePasswordInputValue = (value: string) => {
        setPassword(value);
    };

    const handleCodeValue = (value: string) => {
        setCode(value);
    };

    const signIn = () => {
        const reqBody = {
            email: username,
            password: password,
            code: code,
        };

        Axios.post('https://api.mobininvestment.com/public/sessions', reqBody)
            .then(Response => {
                if (Response.status === 201 && Response.data.token) {
                    window.localStorage.setItem('token', Response.data.token);
                    const parsedJWT = parseJwt(Response.data.token);
                    window.localStorage.setItem('role', parsedJWT.role);
                    dispatch(setUserRoleAction(parsedJWT.role));
                }

                if (Response.status === 202) {
                    Swal.fire({
                        position: 'center',
                        icon: 'info',
                        text: 'لطفا شناسه دو عاملی را به درستی وارد نمایید',
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
            .catch(error => {
                const status = error && error.response && error.response.status;

                if (status === 401 || status === 422) {
                    Swal.fire({
                        position: 'center',
                        icon: 'info',
                        text: 'نام کاربری یا رمز عبور نادرست می باشد',
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
    };

    return (
        <div className={styles.container}>
            <div className={styles.form_wrapper}>
                <img src={logo} alt="logo" />
                <div className={styles.login_title}>
                    <div><span className='text-cool-blue'>ورود</span> / <span>ثبت نام</span></div>
                </div>
                <CustomInput
                    name='signin_username'
                    id='signin_username_input'
                    type='text'
                    autoFocus
                    placeholder='نام کاربری'
                    inputValue={username}
                    handleChangeInput={handleUsernameInputValue}
                />
                <CustomInput
                    id='signin_password_input'
                    type='password'
                    placeholder='رمز عبور'
                    inputValue={password}
                    handleChangeInput={handlePasswordInputValue}
                />
                <CustomInput
                    id='signin_code_input'
                    type='text'
                    placeholder='شناسه دو عاملی (در صورت فعال بودن)'
                    inputValue={code}
                    handleChangeInput={handleCodeValue}
                />
                <button
                    disabled={(username === '' || password === '')}
                    className={styles.button}
                    onClick={signIn}
                >
                    ورود
                </button>
                <div className={styles.links_wrapper}>
                    <Link to='/signup'>عضو جدید هستم</Link>
                    <Link to='/forgetpassword'>فراموشی رمز عبور</Link>
                </div>
            </div>
            <div>
                <img src={chainPic} className={styles.chain_pic} alt="illustration" />
                <img src={chainPicMobile} className={styles.chain_pic_mobile} alt="illustration" />
            </div>
        </div>
    );
}

export default SignIn;
