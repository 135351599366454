import { useState, useEffect } from 'react';
import { Router, Switch } from 'react-router-dom';
import history from '../helpers/history';
import { useTypedSelector } from '../hooks/useTypedSelector';
import RootRoutes from './layout/root';
import AdminRoutes from './layout/admin';
import MemberRoutes from './layout/member';

function Routes() {
    const [routeState, setRouteState] = useState('root');
    const { userRole } = useTypedSelector((state) => state);

    useEffect(() => {
        const role = window.localStorage.getItem('role') || userRole;

        if (role) {
            setRouteState(role);
        }
    })

    const routeFunc = () => {
        switch (routeState) {
            case 'admin':
                return <AdminRoutes />;
            case 'member':
                return <MemberRoutes />;
            default:
                return <RootRoutes />;
        };
    };

    return (
        <Router history={history}>
            <Switch>
                {routeFunc()}
            </Switch>
        </Router>
    );
}

export default Routes;
