import React, { Component } from 'react';
import styles from './style.module.scss';
import { localeDate } from '../../helpers/localeDate';
// import DonutChart from '../../containers/DonutChart';
// import LineChart from '../../containers/LineChart';
import Axios from 'axios';
import Spinner from '../../components/Spinner';
import DonutChart from '../../containers/DonutChart';

interface State {
    asset: number;
    depositsTableData: [] | null;
    withdrawsTableData: [] | null;
    portfolioData: [] | null;
    chartData: [] | null;
    statsData: {} | null;
    intervalData: [] | null;
    fetchingDeposits: boolean;
    fetchingWithdraws: boolean;
    userPortfolioData: any;
}

class MemberDashboard extends Component<{}, State> {
    state = {
        asset: 0,
        depositsTableData: null,
        withdrawsTableData: null,
        portfolioData: null,
        chartData: null,
        statsData: {
            asset: 0,
            deposited: 0,
            interest_in: 0
        },
        intervalData: null,
        fetchingDeposits: true,
        fetchingWithdraws: true,
        userPortfolioData: null
    };

    public componentDidMount() {
        const token = window.localStorage.getItem('token');
        if (token) {

            // Axios.get('https://api.mobininvestment.com/members/profile', { headers: { Authorization: `bearer ${token}` } })
            //     .then(Response => {
            //         if (Response.status === 200) {
            //             this.setState({ asset: Response.data.asset });
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     })

            Axios.get('https://api.mobininvestment.com/members/deposits', { headers: { Authorization: `bearer ${token}` } })
                .then(Response => {
                    if (Response.status === 200 || Response.status === 201) {
                        this.setState({
                            fetchingDeposits: false,
                            depositsTableData: Response.data
                        });
                    }
                })
                .catch(error => {
                    this.setState({ fetchingDeposits: false });
                    console.log(error);
                })

            Axios.get('https://api.mobininvestment.com/members/withdraws', { headers: { Authorization: `bearer ${token}` } })
                .then(Response => {
                    if (Response.status === 200 || Response.status === 201) {
                        this.setState({
                            fetchingWithdraws: false,
                            withdrawsTableData: Response.data,
                        });
                    }
                })
                .catch(error => {
                    this.setState({ fetchingWithdraws: false });
                    console.log(error);
                })

            Axios.get(`https://api.mobininvestment.com/members/piechart`, { headers: { Authorization: `bearer ${token}` } })
                .then(Response => {
                    if (Response.status === 200 || Response.status === 201) {
                        this.setState({ userPortfolioData: Response.data.map(i => ({ ...i, share: +i.share })) });
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            // Axios.get('https://api.mobininvestment.com/members/chart', { headers: { Authorization: `bearer ${token}` } })
            //     .then(Response => {
            //         if (Response.status === 200 || Response.status === 201) {
            //             this.setState({ chartData: Response.data });
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     })

            // Axios.get('https://api.mobininvestment.com/members/portfolio', { headers: { Authorization: `bearer ${token}` } })
            //     .then(Response => {
            //         if (Response.status === 200 || Response.status === 201) {
            //             this.setState({ portfolioData: Response.data });
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     })

            // Axios.get('https://api.mobininvestment.com/members/chart/stats', { headers: { Authorization: `bearer ${token}` } })
            //     .then(Response => {
            //         if (Response.status === 200 || Response.status === 201) {
            //             this.setState({ statsData: Response.data });
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     })

            // Axios.get('https://api.mobininvestment.com/members/interval', { headers: { Authorization: `bearer ${token}` } })
            //     .then(Response => {
            //         if (Response.status === 200 || Response.status === 201) {
            //             this.setState({ intervalData: Response.data });
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     })
        }
    };

    render() {
        // const { chartData, portfolioData, statsData } = this.state;

        return (
            <div className={styles.container}>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>لیست واریز</span>
                        <span className={styles.hr_line} />
                    </div>
                    <div className="d-flex justify-content-center py-4">
                        <div className={styles.detailbox_table_wrapper}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>سرمایه گذاری اولیه</th>
                                        <th>سود پرتفوی</th>
                                        <th>ارزش فعلی</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchingDeposits ?
                                        <tr>
                                            <td colSpan={3}><Spinner color='#3ea9c2' /></td>
                                        </tr> :
                                        this.depositsTable()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>لیست برداشت</span>
                        <span className={styles.hr_line} />
                    </div>
                    <div className="d-flex justify-content-center py-4">
                        <div className={styles.detailbox_table_wrapper}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>مقدار</th>
                                        <th>شماره واریز</th>
                                        <th>وضعیت</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchingWithdraws ?
                                        <tr>
                                            <td colSpan={3}> <Spinner color='#3ea9c2' /></td>
                                        </tr> :
                                        this.withdrawsTable()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>نمودار حساب</span>
                        <span className={styles.hr_line} />
                    </div>
                    <div className="d-flex justify-content-center py-4">
                        <div className={styles.detailbox_chart}>
                            <DonutChart
                                cx={150}
                                cy={150}
                                width={360}
                                height={360}
                                innerRadius={55}
                                outerRadius={100}
                                data={this.state.userPortfolioData}
                                noDataStyle={{ marginTop: '120px' }}
                            />
                        </div>
                    </div>
                </div>


                {/* <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>وضعیت فعلی</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="d-none d-md-flex justify-content-center">
                        <DonutChart
                            data={portfolioData}
                            width={450}
                            height={450}
                        />
                    </div>
                    <div className="d-flex d-md-none justify-content-center">
                        <DonutChart
                            width={340}
                            height={340}
                            innerRadius={75}
                            outerRadius={90}
                            cx={150}
                            cy={150}
                            data={portfolioData} />
                    </div>
                    <div className={styles.statsWrapper}>
                        <div className={styles.statsBlock}>
                            <span className={styles.blockData}>{statsData.asset}</span>
                            <br />
                            <span className={styles.blockTitle}>کل سرمایه فعلی</span>
                        </div>
                        <div className={styles.statsBlock}>
                            <span className={styles.blockData}>{statsData.deposited}</span>
                            <br />
                            <span className={styles.blockTitle}>سرمایه ابتدای دوره</span>
                        </div>
                        <div className={styles.statsBlock}>
                            <span className={styles.blockData}>{statsData.interest_in} $</span>
                            <br />
                            <span className={styles.blockTitle} >سود در آخرین دوره</span>
                        </div>
                    </div>
                    <div className={styles.interval}>
                        <div className="d-flex align-items-center">
                            <span className={styles.interval_title}>سابقه دوره های سرمایه گذاری</span><span className={styles.hr_line}></span>
                        </div>
                        <div className="py-4">
                            {this.intervalTable()}
                        </div>
                    </div>
                </div> */}
                {/* <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>نمودار دارایی</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="d-flex justify-content-center py-4">
                        <LineChart data={chartData} />
                    </div>
                </div> */}
                {/* <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>تاریخچه مالی</span><span className={styles.hr_line}></span>
                    </div>

                    <div className="pt-5">
                        <ul className="nav nav-tabs custom-nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">واریز</button>
                            </li>
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">برداشت</button>
                            </li>
                        </ul>
                        <div className="tab-content custom-tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className={styles.detailbox_table_wrapper}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>تاریخ</th>
                                                <th>مقدار</th>
                                                <th>وضعیت</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.depositsTable()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className={styles.detailbox_table_wrapper}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>تاریخ</th>
                                                <th>مقدار</th>
                                                <th>وضعیت</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.withdrawsTable()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }

    intervalTable = () => {
        const { intervalData } = this.state;

        if (intervalData && intervalData.length !== 0) {
            return (
                <table>
                    <thead>
                        <tr>
                            <th>تاریخ شروع دوره</th>
                            <th>مجموع سود  پیش از دوره</th>
                            <th>سرمایه ابتدا  دوره</th>
                            <th>سود در دوره</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            intervalData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{localeDate(item.created_at, 'date')}</td>
                                        <td>{item.interest_start}</td>
                                        <td>{item.initial_value}</td>
                                        <td>{item.interest_in}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        } else {
            return (
                <div className="text-center">
                    <span>اطلاعاتی موجود نمی باشد</span>
                </div>
            )
        }
    };

    depositsTable = () => {
        const { depositsTableData } = this.state;

        if (depositsTableData && depositsTableData.length !== 0) {
            return (
                <React.Fragment>
                    {
                        depositsTableData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{parseFloat(item.amount).toFixed(8)}</td>
                                    <td>
                                        {(item.estimated_worth && item.amount) ? `${(((parseFloat(item.estimated_worth) - parseFloat(item.amount)) / parseFloat(item.amount)) * 100).toFixed(2)}%` : '----'}
                                    </td>
                                    <td>{parseFloat(item.estimated_worth).toFixed(8)}</td>
                                </tr>
                            )
                        })
                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={3}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    }

    withdrawsTable = () => {
        const { withdrawsTableData } = this.state;

        if (withdrawsTableData && withdrawsTableData.length !== 0) {
            return (
                <React.Fragment>
                    {
                        withdrawsTableData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.amount}</td>
                                    <td>{item.deposit_id}</td>
                                    <td>{item.state === 'completed' ? 'انجام شده' : 'در حال انجام'}</td>
                                </tr>
                            )
                        })
                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={3}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    }
}

export default MemberDashboard;