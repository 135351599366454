import { useState } from 'react';
import styles from './style.module.scss';
import { CustomInput } from '../../components/CustomInput';
import Swal from 'sweetalert2';
import Axios from 'axios';

const logo = require('../../assets/images/logo.png').default;
const chainPic = require('../../assets/images/chain.png').default;
const chainPicMobile = require('../../assets/images/chain_mobile.png').default;

function ForgetPassword(props: any) {
    const [email, setEmail] = useState('');

    const handleEmailInputValue = (value: string) => {
        setEmail(value);
    };

    const submitEmail = () => {
        const reqBody = { email };

        Axios.patch('https://api.mobininvestment.com/public/users/password', reqBody)
            .then(Response => {
                if (Response.status === 201) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'ایمیل بازیابی رمز عبور با موفقیت ارسال شد',
                        showConfirmButton: false,
                        timer: 3500,
                    });

                    window.localStorage.setItem("rpEmail", email);
                    props.history.push('/resetpassword');
                }
            })
            .catch(error => {
                console.log(error);
                const status = error && error.response && error.response.status;
                if (status === 422) {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        text: 'ایمیل یافت نشد',
                        showConfirmButton: false,
                        timer: 3500,
                    });
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        text: 'مشکلی بوجود آمده است. لطفا مجددا تلاش نمایید',
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            });
    };

    return (
        <div className={styles.container}>
            <div className={styles.form_wrapper}>
                <img src={logo} alt="logo" />
                <div className={styles.login_title}>
                    <div><span>فراموشی رمز عبور</span></div>
                </div>
                <CustomInput
                    type='email'
                    placeholder='آدرس پست الکترونیکی خود را وارد نمایید'
                    inputValue={email}
                    handleChangeInput={handleEmailInputValue}
                />
                <button
                    disabled={email === ''}
                    className={styles.button}
                    onClick={submitEmail}
                >
                    ادامه
                </button>
            </div>
            <div>
                <img src={chainPic} className={styles.chain_pic} alt="illustration" />
                <img src={chainPicMobile} className={styles.chain_pic_mobile} alt="illustration" />
            </div>
        </div>
    );
}

export default ForgetPassword;