import React, { Component } from 'react';
import styles from './style.module.scss';

interface Props {
    color: string;
}

class Spinner extends Component<Props> {
    render() {
        const { color } = this.props;
        return (<div className={styles.hollow_dots_spinner}>
            <div className={styles.dot} style={{ borderColor: color }}></div>
            <div className={styles.dot} style={{ borderColor: color }}></div>
            <div className={styles.dot} style={{ borderColor: color }}></div>
        </div>);
    }
};

export default Spinner;
