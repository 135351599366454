type HexColors = string[];

export const hexColors: HexColors = [
    "#0088FE",
    "#00C49F",
    "#FF8042",
    "#FFBB28",
    "#FFB6C1",
    "#CD69C9",
    "#7D26CD",
    "#3D59AB",
    "#B0C4DE",
    "#009ACD",
    "#00F5FF",
    "#79CDCD",
    "#00C78C",
    "#76EEC6",
    "#00EE76",
    "#ADFF2F",
    "#A2CD5A",
    "#698B22",
    "#FFFFE0",
    "#FFFF00",
    "#808000",
    "#FFF68F",
    "#FFD700",
    "#8B7500",
    "#CDBA96",
    "#FFE4B5",
    "#FF9912",
    "#CD6600",
    "#CDAF95",
    "#CD3700",
    "#EEE9E9",
    "#FF4040",
    "#8E388E",
    "#7171C6",
    "#7D9EC0",
    "#388E8E",
    "#8E8E38",
    "#71C671",
    "#C67171",
    "#EAEAEA",
    "#00008B",
    "#6495ED",
    "#551A8B",
    "#CD8C95",
    "#6A5ACD",
    "#00FF7F",
    "#BDFCC9",
    "#6B8E23",
    "#CDCD00",
    "#9C661F",
    "#8B5742",
    "#FF4500",
    "#B0171F",
];