import { Route, Redirect } from 'react-router-dom';
import { SideBar } from '../../containers/SideBar';
import { Header } from '../../containers/Header';
import MemberProfile from '../../screens/MemberProfileScreen';
import MemberDashboard from '../../screens/MemberDashboardScreen';

function MemberRoutes() {
    return (
        <div className='d-flex flex-row'>
            <div className="d-none d-md-block">
                <SideBar />
            </div>
            <div className='d-flex flex-column flex-grow-1'>
                <Header />
                <div className="d-md-none">
                    <SideBar />
                </div>
                <Route path='/' exact component={MemberProfile} />
                <Route path='/dashboard' component={MemberDashboard} />
                <Route>
                    <Redirect to="/" />
                </Route>
            </div>
        </div>
    );
}

export default MemberRoutes;