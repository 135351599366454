import React, { Component } from 'react';
import { CustomInput } from '../../components/CustomInput';
import styles from './style.module.scss';
import { connect } from 'react-redux';
import { setUserFullNameAction } from '../../state/actions';
import { localeDate } from '../../helpers/localeDate';
import Axios from 'axios';
import Swal from 'sweetalert2';
import QRCode from "react-qr-code";

class MemberProfile extends Component {
    state = {
        profileDetail: {
            created_at: '---',
            fullname: '---',
            email: '---',
            phone: '---',
            role: '---',
            state: '---',
            otp_enabled: false,
        },
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        QRCodeURI: '',
        OTP: '',
    };

    public componentDidMount() {
        const token = window.localStorage.getItem('token');
        if (token) {
            Axios.get('https://api.mobininvestment.com/members/profile', { headers: { Authorization: `bearer ${token}` } })
                .then(Response => {
                    if (Response.status === 200 || Response.status === 201) {
                        this.setState({ profileDetail: Response.data });

                        window.localStorage.setItem('userfullname', Response.data.fullname);
                        setUserFullNameAction(Response.data.fullname);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        } else {

        }
    };

    public render() {
        const { fullname, created_at, email, phone, role, state } = this.state.profileDetail;

        return (
            <div className={styles.container}>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>مشخصات کاربر</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="">
                        <div className="container">
                            <div className="row my-md-5 pt-3">
                                <div className="col-12 col-md-4">نام و نام خانوادگی : {fullname}</div>
                                <div className="col-12 col-md-4">سطح دسترسی :  {this.roleTitle(role)}</div>
                                <div className="col-12 col-md-4">وضعیت کاربر :  {this.stateTitle(state)} </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-4">ایمیل : {email}</div>
                                <div className="col-12 col-md-4">شماره تماس : {phone} </div>
                                <div className="col-12 col-md-4">تاریخ عضویت :  {created_at !== '---' && localeDate(created_at, 'date')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>تغییر رمز عبور</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="container">
                        <div className="row my-md-5 pt-3">
                            <div className="col-12 col-md-6">
                                <CustomInput
                                    type='password'
                                    placeholder='رمز عبور فعلی'
                                    inputValue={this.state.oldPassword}
                                    handleChangeInput={this.handleOldPasswordInputValue}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <CustomInput
                                    type='password'
                                    placeholder='تکرار رمز عبور'
                                    inputValue={this.state.newPassword}
                                    handleChangeInput={this.handleNewPasswordInputValue}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <CustomInput
                                    type='password'
                                    placeholder='رمز عبور جدید'
                                    inputValue={this.state.confirmNewPassword}
                                    handleChangeInput={this.handleConfirmPasswordInputValue}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <button
                                    disabled={this.buttonState()}
                                    className={styles.button}
                                    onClick={this.changePassword}
                                >
                                    ثبت
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>شناسه دوعاملی‌</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="container pt-3 pt-md-0">
                        {this.state.profileDetail.otp_enabled ?
                            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', marginTop: '10px', gap: '8px' }}>
                                <i className='fas fa-lock' />
                                <span>شناسه دو عاملی فعال می‌باشد.</span>
                            </div>
                            : <button
                                data-bs-toggle="modal"
                                className={styles.otp_button}
                                onClick={this.generateQRCode}
                                data-bs-target="#twoFactorModal"
                            >
                                فعال سازی شناسه دو عاملی
                            </button>}
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="twoFactorModal"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="twoFactorModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog  modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className={styles.two_factor_form}>
                                    <QRCode value={this.state.QRCodeURI} />
                                    <CustomInput
                                        type='text'
                                        placeholder='کد شناسه دو عاملی'
                                        inputValue={this.state.OTP}
                                        className={styles.two_factor_form_input}
                                        handleChangeInput={this.handleOTPInputValue}
                                    />
                                    <button
                                        className={styles.two_factor_form_btn}
                                        data-bs-toggle="modal"
                                        data-bs-target="#twoFactorModal"
                                        onClick={this.setOTPCode}
                                    >
                                        ارسال
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private roleTitle = (role: string) => {
        switch (role) {
            case 'admin':
                return 'ادمین';
            case 'member':
                return 'کاربر عادی';
            case 'trader':
                return 'معامله گر';
            default:
                return '';
        }
    }

    private stateTitle = (role: string) => {
        switch (role) {
            case 'active':
                return 'فعال';
            case 'pending':
                return 'غیر فعال';
            default:
                return '';
        }
    }

    private handleOldPasswordInputValue = (value: string) => {
        this.setState({ oldPassword: value });
    };

    private handleNewPasswordInputValue = (value: string) => {
        this.setState({ newPassword: value });
    };

    private handleConfirmPasswordInputValue = (value: string) => {
        this.setState({ confirmNewPassword: value });
    };

    private handleOTPInputValue = (value: string) => {
        this.setState({ OTP: value });
    };

    private buttonState = () => {
        const state =
            this.state.oldPassword === '' ||
            this.state.newPassword === '' ||
            this.state.confirmNewPassword === '';

        return state;
    };

    private changePassword = () => {
        const {
            oldPassword,
            newPassword,
            confirmNewPassword
        } = this.state;

        if (newPassword === confirmNewPassword) {
            const token = window.localStorage.getItem('token');
            const reqBody = {
                password: oldPassword,
                new_password: newPassword
            };

            Axios.post('https://api.mobininvestment.com/members/password', reqBody, { headers: { Authorization: `bearer ${token}` } })
                .then(Response => {
                    if (Response.status === 202 || Response.status === 200 || Response.status === 201) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            text: 'رمز عبور جدید با موفقیت ثبت شد',
                            showConfirmButton: false,
                            timer: 3500,
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: 'رمز عبور مطابقت ندارد',
                showConfirmButton: false,
                timer: 3500,
            });
        }
    };

    private generateQRCode = () => {
        const token = window.localStorage.getItem('token');
        Axios.post('https://api.mobininvestment.com/members/otp', {}, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 202 || Response.status === 201 || Response.status === 200) {
                    this.setState({ QRCodeURI: Response.data.uri });
                }
            })
            .catch(error => {
                console.log(error);
            })
    };

    private setOTPCode = () => {
        const token = window.localStorage.getItem('token');
        Axios.post('https://api.mobininvestment.com/members/otp', { code: this.state.OTP }, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 202 || Response.status === 201 || Response.status === 200) {
                    this.setState({ profileDetail: { ...this.state.profileDetail, otp_enabled: true } });
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'شناسه دو عاملی با موفقیت فعال شد',
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    text: 'اطلاعات وارد شده نامعتبر می‌باشد.',
                    showConfirmButton: false,
                    timer: 3500,
                });
                console.log(error);
            })
    };
}

export default connect(null, { setUserFullNameAction })(MemberProfile);
