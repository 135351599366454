import { useState } from 'react';
import styles from './style.module.scss';
import { CustomInput } from '../../components/CustomInput';
import { withRouter } from 'react-router-dom';
import { errorHandler } from '../../helpers/errorHandler';
import Swal from 'sweetalert2';
import Axios from 'axios';

const logo = require('../../assets/images/logo.png').default;
const chainPic = require('../../assets/images/chain.png').default;
const chainPicMobile = require('../../assets/images/chain_mobile.png').default;

interface RequestBody {
    fullname: string,
    email: string;
    password: string;
    phone: string;
};

function SignUp(props: any) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleNameInputValue = (value: string) => {
        setName(value);
    };

    const handleEmailInputValue = (value: string) => {
        setEmail(value);
    };

    const handlePhoneInputValue = (value: string) => {
        setPhone(value);
    };

    const handlePasswordInputValue = (value: string) => {
        setPassword(value);
    };

    const handleConfirmPasswordInputValue = (value: string) => {
        setConfirmPassword(value);
    };

    const registerButtonState = () => {
        const state =
            name === '' ||
            email === '' ||
            phone === '' ||
            password === '' ||
            confirmPassword === '';

        return state;
    };

    const register = () => {
        const reqBody: RequestBody = {
            fullname: name,
            email: email,
            password: password,
            phone: phone
        };

        if (password === confirmPassword) {
            Axios.post('https://api.mobininvestment.com/public/users', reqBody)
                .then(Response => {
                    if (Response.status === 200 || Response.status === 201) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            text: 'اطلاعات شما با موفقیت ثبت شد',
                            showConfirmButton: false,
                            timer: 3500,
                        });

                        props.history.push('/');
                    }
                })
                .catch(error => {
                    console.log(error.response.data.error);
                    const errorMessage = errorHandler(error.response.data.error)
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        text: errorMessage,
                        showConfirmButton: false,
                        timer: 3500,
                    });
                });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: 'رمز عبور مطابقت ندارد',
                showConfirmButton: false,
                timer: 3500,
            });
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.form_wrapper}>
                <div>

                    <img src={logo} alt="logo" />
                </div>
                <div className={styles.login_title}>
                    <div><span>ورود</span> / <span className='text-cool-blue'>ثبت نام</span></div>
                </div>
                <CustomInput
                    type='text'
                    autoFocus
                    placeholder='نام و نام خانوادگی'
                    inputValue={name}
                    handleChangeInput={handleNameInputValue}
                />
                <CustomInput
                    type='email'
                    placeholder='آدرس پست الکترونیکی'
                    inputValue={email}
                    handleChangeInput={handleEmailInputValue}
                />
                <CustomInput
                    type='text'
                    placeholder='شماره تماس'
                    inputValue={phone}
                    handleChangeInput={handlePhoneInputValue}
                    maxLength={11}
                />
                <CustomInput
                    type='password'
                    placeholder='رمز عبور'
                    inputValue={password}
                    handleChangeInput={handlePasswordInputValue}
                />
                <CustomInput
                    type='password'
                    placeholder='تکرار رمز عبور'
                    inputValue={confirmPassword}
                    handleChangeInput={handleConfirmPasswordInputValue}
                />
                <button
                    disabled={registerButtonState()}
                    className={styles.button}
                    onClick={register}
                >
                    ثبت نام
                </button>
            </div>
            <div>
                <img src={chainPic} className={styles.chain_pic} alt="illustration" />
                <img src={chainPicMobile} className={styles.chain_pic_mobile} alt="illustration" />
            </div>
        </div>
    );
}

export default withRouter(SignUp);