import React, { Component } from 'react';
import styles from './style.module.scss';
import { CustomInput } from '../../components/CustomInput';
import { supportedCurrencies } from '../../constants';
import Swal from 'sweetalert2';
import Axios from 'axios';

const binanceIcon = require('../../assets/icons/icon-binance.svg').default;
class Order extends Component {
    state = {
        iconURL: null,
        currencyInputValue: '',
        currencyList: [],
        selectedCurrency: null,
        selectedAccountID: null,
        selectedAccountIndex: null,
        binanceAccountList: [],
        orderType: 'MARKET',
        marketOrderQuantity: '',
        limitOrderQuantity: '',
        limitOrderPrice: '',
        stopLossOrderQuantity: '',
        stopLossOrderPrice: '',
        stopLossLimitOrderQuantity: '',
        stopLossLimitOrderStopPrice: '',
        stopLossLimitOrderPrice: '',
    };

    componentDidMount() {
        const token = window.localStorage.getItem('token');

        Axios.get('https://api.mobininvestment.com/binance/', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    this.setState({ binanceAccountList: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    accountsMapper = () => {
        const { binanceAccountList } = this.state;

        if (binanceAccountList.length !== 0) {
            return (
                binanceAccountList.map((item, index) => {
                    return (
                        <div
                            className={this.state.selectedAccountIndex === index ? styles.card_wallets_selected : styles.card_wallets}
                            key={index}
                            onClick={() => this.setState({ selectedAccountID: item.id, selectedAccountIndex: index })}
                        >
                            <div className={styles.card_wallets_titleWrapper}>
                                <img src={binanceIcon} alt="wallet-icon" />
                                <span>{item.label}</span>
                            </div>
                            <div className={styles.card_wallets_iconWrapper}>

                            </div>
                        </div>
                    )
                })
            )
        }
    };

    currencySelectMapper = () => {
        return (
            supportedCurrencies.map((item, index) => {
                return <option key={index} value={item}>{item}</option>
            })
        );
    };

    currencyBox = () => {
        const { selectedCurrency } = this.state;

        if (!selectedCurrency) {
            return (
                <div className={styles.deactive_currencybox}>
                    <i className="fas fa-coins fa-3x p-2" />
                    <i className="fas fa-plus fa-2x" />
                </div>
            );
        } else {
            try {
                const imgSrc = require(`../../assets/icons/currencies/${selectedCurrency.toLowerCase()}.svg`).default;

                return (
                    <div className={styles.currencybox}>
                        <div className={styles.card_wallets_titleWrapper}>
                            <img src={imgSrc} alt="currency-icon" />
                        </div>
                        <div className={styles.currencybox_title}>
                            <span>{`${selectedCurrency.toUpperCase()}/USDT`}</span>
                        </div>
                    </div>
                );
            } catch (error) {
                const imgSrc = require(`../../assets/icons/currencies/default.png`).default;

                return (
                    <div className={styles.currencybox}>
                        <div className={styles.card_wallets_titleWrapper}>
                            <img src={imgSrc} alt="currency-icon" />
                        </div>
                        <div className={styles.currencybox_title}>
                            <span>{`${selectedCurrency.toUpperCase()}/USDT`}</span>
                        </div>
                    </div>
                );
            }
        }
    };

    handleMarketQuantityInputValue = (value: string) => {
        this.setState({ marketOrderQuantity: value });
    };

    handleLimitQuantityInputValue = (value: string) => {
        this.setState({ limitOrderQuantity: value });
    };

    handleLimitPriceInputValue = (value: string) => {
        this.setState({ limitOrderPrice: value });
    };

    handleStopLossQuantityInputValue = (value: string) => {
        this.setState({ stopLossOrderQuantity: value });
    };

    handleStopLossPriceInputValue = (value: string) => {
        this.setState({ stopLossOrderPrice: value });
    };

    handleStopLossLimitQuantityInputValue = (value: string) => {
        this.setState({ stopLossLimitOrderQuantity: value });
    };

    handleStopLossLimitStopPriceInputValue = (value: string) => {
        this.setState({ stopLossLimitOrderStopPrice: value });
    };

    handleStopLossLimitPriceInputValue = (value: string) => {
        this.setState({ stopLossLimitOrderPrice: value });
    };

    submitOrder = (side: string): void => {
        const token = window.localStorage.getItem('token');
        const { selectedAccountID, orderType, selectedCurrency } = this.state;
        let reqBody;

        switch (orderType) {
            case 'MARKET': {
                const quantity = this.state.marketOrderQuantity;
                reqBody = {
                    symbol: `${selectedCurrency}USDT`,
                    side,
                    order_type: orderType,
                    quantity: parseFloat(quantity),
                };
                break;
            }
            case 'LIMIT': {
                const quantity = this.state.limitOrderQuantity;
                const price = this.state.limitOrderPrice;
                reqBody = {
                    symbol: `${selectedCurrency}USDT`,
                    side,
                    order_type: orderType,
                    quantity: parseFloat(quantity),
                    price: parseFloat(price),
                };
                break;
            }
            case 'STOP_LOSS': {
                const quantity = this.state.stopLossOrderQuantity;
                const price = this.state.stopLossOrderPrice;
                reqBody = {
                    symbol: `${selectedCurrency}USDT`,
                    side,
                    order_type: orderType,
                    quantity: parseFloat(quantity),
                    price: parseFloat(price),
                };
                break;
            }
            case 'STOP_LOSS_LIMIT': {
                const quantity = this.state.stopLossLimitOrderQuantity;
                const price = this.state.stopLossLimitOrderPrice;
                const stopPrice = this.state.stopLossLimitOrderStopPrice;

                reqBody = {
                    symbol: `${selectedCurrency}USDT`,
                    side,
                    order_type: orderType,
                    quantity: parseFloat(quantity),
                    stop_price: parseFloat(stopPrice),
                    price: parseFloat(price),
                };
                break;
            }
            default:
                return;
        }

        if (selectedAccountID === null || selectedCurrency === null) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                text: 'لطفا ارز و حساب مورد نظر را انتخاب کنید',
                showConfirmButton: false,
                timer: 3500,
            });
        } else {
            Axios.post(`https://api.mobininvestment.com/binance/${selectedAccountID}/orders`, reqBody, { headers: { Authorization: `bearer ${token}` } })
                .then(Response => {
                    if (Response.status === 201 || Response.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            text: 'سفارش با موفقیت ثبت شد',
                            showConfirmButton: false,
                            timer: 3500,
                        });
                    }
                })
                .catch(error => console.log(error))
        }
    };

    currencyList = (value: string) => {
        this.setState({ currencyInputValue: value });
        const trimVal = value.trim();

        if (trimVal === '') {
            this.setState({ currencyList: [] });
        } else {
            const newList = supportedCurrencies.filter(item => {
                return item.includes(value.toUpperCase());
            });

            this.setState({ currencyList: newList });
        }
    };

    currencyListItems = () => {
        const { currencyList } = this.state;
        if (currencyList.length === 0) {
            return;
        } else {
            return (
                <ul>
                    {currencyList.map((item, index) => {
                        return <li key={index} onClick={() => this.currencySelect(item)} >{item}</li>;
                    })}
                </ul>
            );
        }
    };

    currencySelect = (currency: string): void => {
        this.setState({
            currencyList: [],
            currencyInputValue: currency,
            selectedCurrency: currency,
        });
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>انتخاب رمز ارز</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="row pt-4">
                        <div className="col-12 col-md-5 d-flex align-items-center">
                            <div className={styles.currency_select_container}>
                                <input
                                    type="text"
                                    placeholder='رمز ارز مورد نظر را وارد کنید'
                                    value={this.state.currencyInputValue}
                                    onChange={(e) => this.currencyList(e.target.value)}
                                />
                                <div className={styles.currency_select_list}>
                                    {this.currencyListItems()}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-center mt-4 mt-md-0">
                            {this.currencyBox()}
                        </div>
                    </div>
                </div>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>انتخاب حساب بایننس</span><span className={styles.hr_line}></span>
                    </div>
                    <div className={styles.card_wrapper}>
                        <div className="d-flex">
                            {this.accountsMapper()}
                        </div>
                    </div>
                </div>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>انتخاب مدل سفارش</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="pt-5">
                        <ul className="nav nav-tabs custom-nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="market-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#market"
                                    type="button"
                                    role="tab"
                                    aria-controls="market"
                                    aria-selected="true"
                                    onClick={() => this.setState({ orderType: 'MARKET' })}
                                >
                                    آنی
                                </button>
                            </li>
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="limit-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#limit"
                                    type="button"
                                    role="tab"
                                    aria-controls="limit"
                                    aria-selected="false"
                                    onClick={() => this.setState({ orderType: 'LIMIT' })}
                                >
                                    محدود
                                </button>
                            </li>
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="stoploss-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#stoploss"
                                    type="button"
                                    role="tab"
                                    aria-controls="stoploss"
                                    aria-selected="false"
                                    onClick={() => this.setState({ orderType: 'STOP_LOSS' })}
                                >
                                    استاپ لاس
                                </button>
                            </li>
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="stoplosslimit-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#stoplosslimit"
                                    type="button"
                                    role="tab"
                                    aria-controls="stoplosslimit"
                                    aria-selected="false"
                                    onClick={() => this.setState({ orderType: 'STOP_LOSS_LIMIT' })}
                                >
                                    استاپ لاس لیمیت
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active pt-4" id="market" role="tabpanel" aria-labelledby="market-tab">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <CustomInput
                                            type='text'
                                            placeholder='مقدار'
                                            inputValue={this.state.marketOrderQuantity}
                                            handleChangeInput={this.handleMarketQuantityInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button
                                            className={styles.button_buy}
                                            onClick={() => this.submitOrder('buy')}
                                        >
                                            خرید
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <button
                                            className={styles.button_sell}
                                            onClick={() => this.submitOrder('sell')}
                                        >
                                            فروش
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade pt-4" id="limit" role="tabpanel" aria-labelledby="limit-tab">
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <CustomInput
                                            type='text'
                                            placeholder='مقدار'
                                            inputValue={this.state.limitOrderQuantity}
                                            handleChangeInput={this.handleLimitQuantityInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <CustomInput
                                            type='text'
                                            placeholder='قیمت'
                                            inputValue={this.state.limitOrderPrice}
                                            handleChangeInput={this.handleLimitPriceInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <button
                                            className={styles.button_buy}
                                            onClick={() => this.submitOrder('buy')}
                                        >
                                            خرید
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <button
                                            className={styles.button_sell}
                                            onClick={() => this.submitOrder('sell')}
                                        >
                                            فروش
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade pt-4" id="stoploss" role="tabpanel" aria-labelledby="stoploss-tab">
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <CustomInput
                                            type='text'
                                            placeholder='مقدار'
                                            inputValue={this.state.stopLossOrderQuantity}
                                            handleChangeInput={this.handleStopLossQuantityInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <CustomInput
                                            type='text'
                                            placeholder='قیمت'
                                            inputValue={this.state.stopLossOrderPrice}
                                            handleChangeInput={this.handleStopLossPriceInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <button
                                            className={styles.button_buy}
                                            onClick={() => this.submitOrder('buy')}
                                        >
                                            خرید
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <button
                                            className={styles.button_sell}
                                            onClick={() => this.submitOrder('sell')}
                                        >
                                            فروش
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade pt-4" id="stoplosslimit" role="tabpanel" aria-labelledby="stoplosslimit-tab">
                                <div className="row">
                                    <div className="col-12 col-md-2">
                                        <CustomInput
                                            type='text'
                                            placeholder='مقدار'
                                            inputValue={this.state.stopLossLimitOrderQuantity}
                                            handleChangeInput={this.handleStopLossLimitQuantityInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <CustomInput
                                            type='text'
                                            placeholder='قیمت'
                                            inputValue={this.state.stopLossLimitOrderPrice}
                                            handleChangeInput={this.handleStopLossLimitPriceInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <CustomInput
                                            type='text'
                                            placeholder='قیمت استاپ'
                                            inputValue={this.state.stopLossLimitOrderStopPrice}
                                            handleChangeInput={this.handleStopLossLimitStopPriceInputValue}
                                        />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <button
                                            className={styles.button_buy}
                                            onClick={() => this.submitOrder('buy')}
                                        >
                                            خرید
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <button
                                            className={styles.button_sell}
                                            onClick={() => this.submitOrder('sell')}
                                        >
                                            فروش
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Order;