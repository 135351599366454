import { combineReducers } from 'redux';
import { SET_USER_ROLE, SET_USER_FULL_NAME } from '../actions/constants';

function setUserRoleReducer(state = 'root', action) {
    if (action.type === SET_USER_ROLE) {
        return action.payload;
    } else {
        return state;
    }
};

function setUserFullNameReducer(state = '...', action) {
    if (action.type === SET_USER_FULL_NAME) {
        console.log(action.payload);

        return action.payload;
    } else {
        return state;
    }
};

export const reducers = combineReducers({
    userRole: setUserRoleReducer,
    userFullName: setUserFullNameReducer,
});

export type RootState = ReturnType<typeof reducers>