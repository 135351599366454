import React, { Component } from 'react';
import styles from './style.module.scss';

interface Props {
    name?: string;
    type: string;
    label?: string;
    handleChangeInput?: (value: string) => void;
    inputValue: string | number;
    handleFocusInput?: () => void;
    placeholder: string;
    classNameLabel?: string;
    classNameInput?: string;
    autoFocus?: boolean;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
    id?: string;
    handleClick?: ((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void);
    isDisabled?: boolean;
    maxLength?: number;
    pattern?: string;
    className?: string;
    autoComplete?: string;
}

interface OnChangeEvent {
    target: {
        value: string;
    };
}

class CustomInput extends Component<Props> {
    render() {
        const {
            name,
            placeholder,
            inputValue,
            type,
            autoFocus,
            readOnly,
            id,
            handleClick,
            isDisabled,
            onKeyPress,
            maxLength,
            pattern,
            className,
        } = this.props;

        return (
            <input
                type={type}
                value={inputValue.toString()}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onChange={e => this.handleChangeValue(e)}
                readOnly={readOnly}
                id={id}
                onClick={handleClick}
                disabled={isDisabled}
                onKeyPress={onKeyPress}
                maxLength={maxLength}
                pattern={pattern}
                className={`${styles.input} ${className}`}
                autoComplete="new-password"
                name={name}
            />
        );
    }

    private handleChangeValue = (e: OnChangeEvent) => {
        this.props.handleChangeInput && this.props.handleChangeInput(e.target.value);
    };
};

export {
    CustomInput,
};
