import { errors } from '../constants';

export function errorHandler(errorArray: string[]) {
    const defaulMessage = 'Something Went Wrong, Please Try Again...';
    let message = '';

    errorArray.forEach((item) => {
        message += `${errors[item]}.  `;
    });

    return message ? message : defaulMessage;
};
