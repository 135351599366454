import React, { Component } from 'react';
import styles from './style.module.scss';
import Swal from 'sweetalert2';
import Axios from 'axios';
class OrderManagement extends Component {
    state = {
        binanceAccountList: [],
        pendingTableData: [],
        tradeHistoryTableData: [],
    };

    componentDidMount() {
        const token = window.localStorage.getItem('token');

        Axios.get('https://api.mobininvestment.com/binance/', { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    this.setState({ binanceAccountList: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    accountSelectMapper = () => {
        const { binanceAccountList } = this.state;

        return (
            binanceAccountList.map((item, index) => {
                return <option key={index} value={item.id}>{item.label}</option>
            })
        );
    };

    ordersHistory = (id: string) => {
        const token = window.localStorage.getItem('token');

        Axios.get(`https://api.mobininvestment.com/binance/${id}/orders`, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    const pendingOrders = Response.data.filter((item) => {
                        return item.state === 'PENDING'
                    });
                    this.setState({ pendingTableData: pendingOrders, tradeHistoryTableData: Response.data });
                }
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'مشکلی به وجود آمده است. لطفا دوباره تلاش کنید',
                    showConfirmButton: false,
                    timer: 3500,
                });
            });
    };

    cancelOrder = (id: string): void => {
        const token = window.localStorage.getItem('token');

        Axios.get(`https://api.mobininvestment.com/binance/orders/${id}`, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'سفارش مورد نظر با موفقیت حذف شد',
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'مشکلی به وجود آمده است. لطفا دوباره تلاش کنید',
                    showConfirmButton: false,
                    timer: 3500,
                });
            });
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.detailbox}>
                    <div className="d-flex align-items-center">
                        <span className={styles.detailbox_title}>مدیریت سفارشات</span><span className={styles.hr_line}></span>
                    </div>
                    <div className="d-flex justify-content-center py-4 ">
                        <select
                            className="form-select form-select-lg"
                            aria-label="Default select example"
                            onChange={(e) => this.ordersHistory(e.target.value)}
                        >
                            <option selected>انتخاب حساب</option>
                            {this.accountSelectMapper()}
                        </select>
                    </div>
                    <div className="pt-5">
                        <ul className="nav nav-tabs custom-nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">سفارشات در انتظار</button>
                            </li>
                            <li className="nav-item custom-nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">سابقه معاملات</button>
                            </li>
                        </ul>
                        <div className="tab-content custom-tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className={styles.detailbox_table_wrapper}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>رمز ارز</th>
                                                <th>خرید / فروش</th>
                                                <th>نوع سفارش</th>
                                                <th>حجم سفارش</th>
                                                <th>قیمت اعمال</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.pendingListTable()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className={styles.detailbox_table_wrapper}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>رمز ارز</th>
                                                <th>خرید / فروش</th>
                                                <th>نوع سفارش</th>
                                                <th>حجم سفارش</th>
                                                <th>قیمت اعمال</th>
                                                <th>وضعیت</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.tradeHistoryListTable()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    pendingListTable = () => {
        const { pendingTableData } = this.state;

        if (pendingTableData.length !== 0) {
            return (
                <React.Fragment>
                    {
                        pendingTableData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.symbol}</td>
                                    <td>{item.side === 'buy' ? 'خرید' : 'فروش'}</td>
                                    <td>{item.order_type === 'MARKET' ? 'بازار' : 'محدود'}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price}</td>
                                    <td><i className="fas fa-times" onClick={() => this.cancelPendingOrder(item.id)} /></td>
                                </tr>
                            );
                        })
                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={7}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    };

    tradeHistoryListTable = () => {
        const { tradeHistoryTableData } = this.state;

        if (tradeHistoryTableData.length !== 0) {
            return (
                <React.Fragment>
                    {
                        tradeHistoryTableData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.symbol}</td>
                                    <td>{item.side === 'buy' ? 'خرید' : 'فروش'}</td>
                                    <td>{item.order_type === 'MARKET' ? 'بازار' : 'محدود'}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price}</td>
                                    <td>{item.state}</td>
                                </tr>
                            );
                        })

                    }
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <td colSpan={7}>
                        اطلاعاتی موجود نمی باشد.
                    </td>
                </tr>
            );
        }
    };

    cancelPendingOrder = (id: number): void => {
        const token = window.localStorage.getItem('token');

        Axios.get(`https://api.mobininvestment.com/binance/orders/${id}`, { headers: { Authorization: `bearer ${token}` } })
            .then(Response => {
                if (Response.status === 200 || Response.status === 201) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        text: 'سفارش مورد نظر با موفقیت لغو شد.',
                        showConfirmButton: false,
                        timer: 3500,
                    });
                }
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'مشکلی به وجود آمده است. لطفا دوباره تلاش کنید',
                    showConfirmButton: false,
                    timer: 3500,
                });
            });
    };
}

export default OrderManagement;