import { SET_USER_ROLE, SET_USER_FULL_NAME } from './constants';
import { SetRole, SetFullName } from './types';

export function setUserRoleAction(role: string): SetRole {
    return ({
        type: SET_USER_ROLE,
        payload: role,
    });
};

export function setUserFullNameAction(fullname: string): SetFullName {
    return ({
        type: SET_USER_FULL_NAME,
        payload: fullname,
    });
};